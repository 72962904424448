.trigger {
  cursor: pointer;
  position: relative;
  width: 100px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  position: fixed;
  background-color: $colorMain;
  @include transition(1.0s $easeInOutQuart);

  @include onlyMobile() {
    width: 80px;
    height: 58px;
  }

  @include onlyDesktop() {
    &:hover {
      .trigger_inner {
        span {
          transition-delay: 0 !important;
        }
      }
    }
  }

  &_inner {
    position: relative;
    width: 50px;
    height: 19px;
    display: block;
    transition: all .4s;
    box-sizing: border-box;

    @include onlyMobile() {
      width: 40px;
      height: 15px;
    }

    span {
      display: inline-block;
      transition: all .4s;
      box-sizing: border-box;
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #fff;

      &:nth-child(1) {
        top: 0;
        left: 0;
        -webkit-animation: menu-bar01 .5s forwards;
        animation: menu-bar01 .5s forwards;
      }

      &:nth-child(2),
      &:nth-child(3) {
        height: 1px;
      }

      &:nth-child(2) {
        top: 50%;
        opacity: 1;
      }

      &:nth-child(3) {
        top: 100%;
        -webkit-animation: menu-bar02 .5s forwards;
        animation: menu-bar02 .5s forwards;
      }

      .show-menu & {
        &:nth-child(1) {
          -webkit-animation: active-menu-bar01 .5s forwards;
          animation: active-menu-bar01 .5s forwards;
          top: -14px;
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          -webkit-animation: active-menu-bar03 .5s forwards;
          animation: active-menu-bar03 .5s forwards;
          top: 28px;
        }
      }
    }
  }

  @keyframes menu-bar01 {
    0% {       transform: translateY(21px) rotate(45deg); }
    50% { transform: translateY(15px) rotate(0); }
    100% { transform: translateY(0) rotate(0); }
  }

  @keyframes menu-bar02 {
    0% {       transform: translateY(-21px) rotate(-45deg); }
    50% { transform: translateY(-15px) rotate(0); }
    100% { transform: translateY(0) rotate(0); }
  }

  @keyframes active-menu-bar01 {
    0% {       transform: translateY(0) rotate(0); }
    50% { transform: translateY(21px) rotate(0); }
    100% { transform: translateY(21px) rotate(45deg); }
  }

  @keyframes active-menu-bar03 {
    0% {       transform: translateY(0) rotate(0); }
    50% { transform: translateY(-21px) rotate(0); }
    100% { transform: translateY(-21px) rotate(-45deg); }
  }
}
