


/** headlineLv1
/**************************************/
.headlineLv1 {
	span {
		font-size: 4.4rem;
		@include fontEn;

		@include onlyMobile() {
		  font-size: 2.4rem;
		}
	}
}
