.header {
	z-index: 10;
}

.globalMenu {
	z-index: 20;
}

.trigger {
	z-index: 30;
}

body {
	&:before {
	  z-index: 100;
	}
}
