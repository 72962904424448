.globalMenu {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: flex-end;

	&:before {
	  content: '';
		background-color: rgba(72, 72, 72, .3);
		position: absolute;
		left: 0;
		top: 0;
		z-index: 0;
		display: block;
		width: 100%;
		height: 100%;
	}

	&_container {
		width: 500px;
		height: 100%;
		background-color: $colorMain;
		position: relative;
		z-index: 1;
    overflow-y: scroll;

		@include onlyMobile() {
			width: 100%;
			overflow-y: scroll;
			-webkit-overflow-scrolling:touch;
		}
	}

	&_close {
	  i {

		}
	}

	&_wrap {
		@include onlyDesktop() {
			padding: 100px;
		}

		@include onlyMobile() {
		  width: math.div(255, $widthMobile) * 100%;
			margin: 0 auto;
			padding: 100px 0;
		}
	}
}



/** globalMenuMain
/**************************************/
.globalMenuMain {
	&_item {
		&[data-children="1"] {

		}

	  > a {
			display: block;
			padding: 16px 0;

			span {
				font-size: 2.0rem;
				color: #fff;
				@include fontEn;

				@include onlyMobile() {
				  font-size: 1.6rem;
				}
			}
		}
	}
}



/** globalMenuConfig
/**************************************/
.globalMenuConfig {
	margin-top: 42px;

	&_item {
		border-bottom: 1px solid #fff;
		padding-bottom: 10px;

		a {
			display: flex;
			align-items: center;
			color: #fff;
			padding: 10px 0 2px;
			margin-top: 20px;

			span {
				font-size: 1.4rem;
				letter-spacing: .1em;
			}

			&:before {
			  width: 35px;
			}
		}

	  &[data-config="mypage"] {
			a {
				@include iconfont('mypage');

				&:before {
				  font-size: 21px;
				}
			}
		}

	  &[data-config="favorite"] {
			a {
				@include iconfont('favorite');

				&:before {
				  font-size: 22px;
				}
			}
		}

	  &[data-config="cart"] {
			a {
				position: relative;
				@include iconfont('cart');

				&:before {
				  font-size: 25px;
				}

				i {
					width: 14px;
					height: 14px;
					background-color: #fff;
					border-radius: 50%;
					position: absolute;
					display: block;
					right: -5px;
					top: -5px;
					color: $colorMain;
					font-size: 10px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-style: normal;
				}
			}
		}
	}
}



/** globalMenuSocial
/**************************************/
.globalMenuSocial {
	display: flex;
	align-items: center;
	margin-top: 55px;

	&_item {
		margin-right: 30px;

		&:last-child {
			margin-right: 0;
		}

		a {
			color: #fff;
		}

	  &[data-social="youtube"] {
			a {
				@include iconfont('youtube');

				&:before {
				  font-size: 28px;
				}
			}
		}

	  &[data-social="twitter"] {
			a {
				@include iconfont('twitter');

				&:before {
				  font-size: 20px;
				}
			}
		}

	  &[data-social="instagram"] {
			a {
				@include iconfont('instagram');

				&:before {
				  font-size: 20px;
				}
			}
		}
	}
}



/** animation
/**************************************/
.globalMenu {
	visibility: hidden;
	pointer-events: none;

	.show-menu & {
		visibility: visible;
		pointer-events: auto;
	}

	&:before {
	  opacity: 0;
		@include transition(1.0s $easeOutQuart .0s);

		.show-menu & {
			opacity: 1;
		}
	}

	&_container {
		transform: translateX(100%);
		@include transition(1.0s $easeOutCirc .0s);

		.show-menu & {
			transform: translateX(0);
		}
	}
}



/** globalMenuChild
/**************************************/
.globalMenuChild {
	padding-left: 30px;

	@include onlyMobile() {
	  padding-left: 20px;
	}

	> a {
		display: block;
		color: #fff;
		padding: 10px 0;

		> span {
			font-size: 1.6rem;
			@include fontEn;

			@include onlyMobile() {
			  font-size: 1.4rem;
			}
		}

		&:last-child {
			margin-bottom: 20px;
		}
	}
}
