@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@200&display=swap');

@mixin fontEn() {
	font-family: 'Work Sans', sans-serif;
  letter-spacing: .075em;
}

@mixin fontJp() {
  font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic", Verdana, Meiryo, sans-serif;
  font-style: normal;
}

@mixin fontMix() {
  font-family: 'Work Sans', YuGothic, "Yu Gothic Medium", "Yu Gothic", Verdana, Meiryo, sans-serif;
}

body {
  @include fontJp;
  -webkit-text-size-adjust: none;
}
