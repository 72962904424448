.homeHero {
	position: relative;

	@include onlyDesktop() {
		height: 100vh;
	}

	&_logo {
		position: absolute;
		left: 40px;
		top: 20px;
		z-index: 1;

		@include onlyMobile() {
		  width: 70px;
			left: 20px;
			top: 12px;
		}
	}

	&_container {
		@include onlyDesktop() {
			height: 100%;
		}
	}

	&_slider {
		height: 100%;
	}

	&_item {
		position: relative;

		@include onlyDesktop() {
			display: flex !important;
			height: 100%;
		}
	}

	&_image {
		position: relative;

		@include onlyDesktop() {
		  width: math.div(1260, $widthBase) * 100%;
			height: 100%;
		}

		@include onlyMobile() {
		  height: 100vw;
		}

		> div {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: {
				repeat: no-repeat;
				position: center;
				size: cover;
			}

			@include onlyDesktop() {
			}
		}
	}

	&_content {
		@include onlyDesktop() {
			width: math.div(660, $widthBase) * 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}



/** slick
/**************************************/
.homeHero {
	.slick {
		&-list {
			height: 100%;
		}

		&-track {
			height: 100%;
		}

		&-slide {
			> div {
				height: 100%;
			}
		}
	}
}



/** homeHeroCard
/**************************************/
.homeHeroCard {
	position: relative;

	@include onlyDesktop() {
		width: 240px;
		margin-top: 40px;
	}

	@include onlyMobile() {
	  display: flex;
		justify-content: center;
		width: math.div(288, $widthMobile) * 100%;
		margin: 50px auto 0;
	}

	&[data-status="new"] {
		&:before {
		  width: 100px;
			height: 75px;
			background-color: #fff;
			border: 1px solid $colorBlack;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			content: 'NEW';
			position: absolute;
			right: -90px;
			top: -85px;
			z-index: 1;
			font-size: 2.0rem;
			@include fontEn;

			@include onlyMobile() {
			  width: 50px;
				height: 38px;
				font-size: 1.0rem;
				right: -20px;
				top: -40px;
			}
		}
	}

	&_image {
		text-align: center;

		@include onlyMobile() {
		  height: 105px;
		}

		img {
			display: inline-block;

			@include onlyMobile() {
			  width: auto;
				height: 100%;
				max-width: none;
			}
		}
	}

	&_content {
		@include onlyDesktop() {
			margin-top: 30px;
		}

		@include onlyMobile() {
			padding-left: 5%;
		}
	}

	&_headline {
		text-align: center;
		font-size: 2.4rem;
		line-height: math.div(38, 24);
		@include fontEn;

		@include onlyMobile() {
		  font-size: 1.8rem;
		}
	}

	.textLink {
		margin-top: 40px;

		@include onlyMobile() {
		  margin-top: 30px;
		}
	}
}



/** homeHeroGauge
/**************************************/
.homeHeroGauge {
	@include onlyDesktop() {
		position: absolute;
		right: 60px;
		bottom: 60px;
	}

	@include onlyMobile() {
	  margin-top: 50px;
	}

	&_list {
		display: flex;

		@include onlyDesktop() {
			justify-content: flex-end;
		}

		@include onlyMobile() {
		  justify-content: center;
		}

		> div {
			background-color: #e5e5e5;
			width: 6px;
			height: 6px;
			border-radius: 3px;
			overflow: hidden;
			position: relative;
			cursor: pointer;
			margin-right: 20px;
			transition: 1.0s $easeOutCirc;

			&:last-child {
				margin-right: 0;
			}

			> i {
				background-color: $colorMain;
				position: absolute;
				left: 0;
				top: 0;
				width: 0;
				height: 100%;
				display: block;
			}

			&.is-active {
				pointer-events: none;
				width: 46px;

				> i {

				}
			}
		}
	}
}
