.bottomMenu {
	@include wrap;

	&_wrap {
		@include onlyDesktop() {
			display: flex;
			justify-content: space-between;
		}
	}

	&_button {
		@include onlyDesktop() {
		  width: math.div(600, $widthMain) * 100%;
		}

	  a {
			height: 202px;
			border-radius: 101px;
			display: flex;
			align-items: center;
			position: relative;
			background-color: #fff;
			padding-left: 13%;
			@include iconfont('arrowRight');

			@include onlyWide() {
			  height: 238px;
				border-radius: 119px;
			}

			@include onlyMobile() {
			  height: 124px;
				border-radius: 64px;
				margin-top: 30px;
			}

			&:before {
				font-size: 19px;
				width: 70px;
				height: 70px;
				border-radius: 50%;
				background-color: #fff;
				position: absolute;
				right: 9%;
				top: calc(50% - 35px);
				z-index: 1;
				color: $colorBlack;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}

	&_title {
	  font-size: 3.2rem;
		@include fontEn;

		@include onlyMobile() {
		  font-size: 1.8rem;
		}
	}
}
