.textLink {
	a {
		padding-bottom: 15px;
		position: relative;

		span {
			font-size: 1.6rem;
			@include fontEn;

			@include onlyMobile() {
			  font-size: 1.4rem;
			}
		}

		&:before {
		  position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 1px;
			background-color: $colorBlack;
			display: block;
			content: '';
		}
	}

	&[data-icon-next="arrowRight"] {
		a {
			@include iconfont('arrowRight', 'after');

			&:after {
			  font-size: 19px;
			}
		}
	}

	&[data-icon-prev="instagram"] {
		a {
			@include iconfont('instagram', 'after');

			&:after {
			  font-size: 20px;
			}
		}
	}

	&[data-icon-prev] {
		a {
			padding-left: 30px;

			&:after {
				position: absolute;
				left: 0;
				top: calc(50% - 19px);
			}
		}
	}

	&[data-icon-next] {
		a {
			padding-right: 30px;

			&:after {
				position: absolute;
				right: 0;
				top: calc(50% - 19px);

				@include onlyMobile() {
				  top: calc(50% - 17px);
				}
			}
		}
	}

	&[data-align="center"] {
		display: flex;
		justify-content: center;
	}
}
