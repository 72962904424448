.rotationLogo {
	margin-top: 120px;
	@include wrap;

	@include onlyDesktop() {
		display: flex;
		justify-content: flex-end;
	}

	@include onlyMobile() {
	  margin-top: 80px;
	}

	&_content {
		margin-right: 80px;
		width: 220px;
		height: 220px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;

		@include onlyMobile() {
		  width: 150px;
			height: 150px;
		}
	}

	&_core {
		position: absolute;

		@include onlyMobile() {
		  width: 49px;
		}
	}

	&_frame {
		position: absolute;
		left: 0;
		top: 0;
	  animation: 30s linear infinite rotationLogo;
	}
}

@keyframes rotationLogo {
	0%{ transform:rotate(0);}
	100%{ transform:rotate(360deg); }
}
