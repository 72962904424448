.footer {
	border-top: 1px solid #E8E8E8;
	padding: 210px 0;
	margin-top: 200px;

	@include onlyMobile() {
	  padding: 60px 0;
		margin-top: 100px;
	}

	&_wrap {
		@include wrap;

		@include onlyDesktop() {
			display: flex;
			flex-direction: row-reverse;
			justify-content: space-between;
		}
	}

	&_column {
		&:nth-child(1) {
			@include onlyDesktop() {
				width: 50%;
				display: flex;
				justify-content: flex-end;
			}
		}

 		&:nth-child(2) {
			@include onlyDesktop() {
				width: 200px;
			}

			@include onlyMobile() {
			  margin-top: 60px;
			}
		}
	}

	&_logo {
		width: 100%;

		@include onlyMobile() {
		  width: 150px;
			margin: 0 auto;
		}

		img {
			width: 100%;
		}
	}

	&_social {
		margin-top: 30px;
		display: flex;
		justify-content: space-between;

		@include onlyMobile() {
		  justify-content: center;
		}

	  a {
			width: 50px;
			height: 50px;
			border-radius: 50%;
			background-color: $colorMain;
			color: #fff;
			display: flex;
			justify-content: center;
			align-items: center;

			@include onlyMobile() {
			  width: 40px;
				height: 40px;
			}

			&[data-social="youtube"] {
				@include iconfont('youtube');

				&:before {
				  font-size: 28px;

					@include onlyMobile() {
					  font-size: 22px;
					}
				}
			}

			&[data-social="twitter"] {
				@include iconfont('twitter');

				@include onlyMobile() {
				  margin: 0 16px;
				}

				&:before {
				  font-size: 20px;

					@include onlyMobile() {
					  font-size: 16px;
					}
				}
			}

			&[data-social="instagram"] {
				@include iconfont('instagram');

				&:before {
				  font-size: 20px;

					@include onlyMobile() {
					  font-size: 16px;
					}
				}
			}
		}
	}

	&_copyright {
		margin-top: 90px;
		font-size: 1.2rem;
		@include fontEn;

		@include onlyMobile() {
		  margin-top: 60px;
			text-align: center;
		}
	}

	&_menu {
		overflow: hidden;

		@include onlyDesktop() {
			width: math.div(100, 3) * 2%;
			margin-top: -20px;
		}

		> div {
			width: 50%;
			float: left;

			> a {
				font-size: 1.5rem;
				display: block;
				@include fontEn;

				@include onlyDesktop() {
					width: 214px;
					padding: 17.5px 0;
				}

				@include onlyMobile() {
				  padding: 15px 0;
				}
			}
		}
	}

	&_config {
		@include onlyDesktop() {
			width: math.div(100, 3) * 1%;
		}

		@include onlyMobile() {
		  margin-top: 60px;
		}

	  > div {
			margin-bottom: 30px;

			> a {
				display: flex;
				align-items: center;
				border-bottom: 1px solid $colorBlack;
				padding-bottom: 10px;

				&:before {
				  width: 34px;
				}

				> span {
					font-size: 1.4rem;
					letter-spacing: .1em;
				}

				&[data-config="mypage"] {
					@include iconfont('mypage');

					&:before {
					  font-size: 22px;
					}
				}

				&[data-config="favorite"] {
					@include iconfont('favorite');

					&:before {
					  font-size: 22px;
					}
				}

				&[data-config="cart"] {
					@include iconfont('cart');

					&:before {
					  font-size: 25px;
					}
				}
			}
		}
	}
}
