body {
	background-color: #FBF8F8;

	&:before {
    content: '';
    display: block;
    background-color: #FBF8F8;
    position: fixed;
    left: 0;
    top: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
  }

	&[data-state="loaded"] {
    &:before {
      transition: .6s $easeOutSine;
      opacity: 0;
    }
  }

	&.show-menu {
		overflow: hidden;
	}

	&[data-state="preload"] {
		* {
			transition: none !important;
		}
	}
}

.container {
	overflow: hidden;
}

.section {
	margin-top: 180px;

	@include onlyMobile() {
	  margin-top: 120px;
	}

	&_wrap {
		position: relative;
	  @include wrap;
	}

	&_more {
	  display: flex;
		justify-content: flex-end;
		margin-top: 80px;

		@include onlyMobile() {
		  margin-top: 60px;
		}

		.itemCarousel + & {
			margin-top: 10px;

			@include onlyMobile() {
			  margin-top: 0;
			}
		}

		.seriesLists + & {
			margin-top: 50px;
		}
	}
}
