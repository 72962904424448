@mixin onlyMobile {
  @media only screen and (max-width: #{$widthBreak}px) {
    @content;
  }
}

@mixin onlyMobile_s {
  @media only screen and (max-width: #{$widthMobile}px) {
    @content;
  }
}

@mixin onlyWide {
  @media only screen and (min-width: 1600px) {
    @content;
  }
}

@mixin onlyTablet() {
  @media only screen and (min-width: #{$widthMobile}px) and (max-width: #{$widthBreak}px) {
    @content;
  }
}

@mixin onlyDesktop {
  @media only screen and (min-width: #{$widthBreak + 1}px) {
    @content;
  }
}

@mixin wrap {
  max-width: 1600px;
  padding-left: 160px;
  padding-right: 160px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 1600px) {
    padding-left: 10%;
    padding-right: 10%;
  }

  @include onlyMobile() {
    padding-left: math.div(30, $widthMobile) * 100%;
    padding-right: math.div(30, $widthMobile) * 100%;
  }
}

@mixin mobileWidth {
  @include onlyMobile() {
    width: math.div(630, $widthMobile) * 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

@mixin clearfix() {
  &:after {
    display: block;
    content: '';
    visibility: hidden;
    clear: both;
    height: 0;
  }
}

@mixin transition($param:0.3s $easeOutQuart 0s) {
  -webkit-transition: $param;
  -moz-transition: $param;
  -o-transition: $param;
  transition: $param;
}

@mixin textOut() {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

@mixin ellipsis() {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin clamp($n: 3) {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: $n;
  -webkit-box-orient: vertical;
}

@mixin center($dir: all) {
  position: absolute;

  @if $dir == all {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  @else if $dir == y {
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
  }
  @else if $dir == x {
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
}

@function vwpc($number) {
  @return math.div($number, $widthBase) * 100vw;
}

@function vwsp($number) {
  @return math.div($number, 750) * 100vw;
}
