.homeMovie {
	position: relative;
	padding-bottom: 180px;
	margin-top: 80px;

	@include onlyMobile() {
	  padding-bottom: 90px;
		margin-top: 60px;
	}

	&:before {
		content: '';
		display: block;
	  width: 100%;
		height: calc(100% - 140px);
		position: absolute;
		z-index: 0;
		left: 0;
		bottom: 0;
		background-color: #fff;

		@include onlyMobile() {
		  height: calc(100% - 80px);
		}
	}

	&_list {
		position: relative;
		z-index: 1;
	}
}

.homeMovieCard {
	position: relative;
	margin-top: 120px;

	@include onlyMobile() {
	  margin-top: 60px;
	}

	&:first-child {
		margin-top: 0;
	}

	&:nth-child(even) {
		a {
			flex-direction: row-reverse;
		}

		.homeMovieCard_image {
			@include onlyWide() {
			  transform: translateX(15%);
			}
		}

		.homeMovieCard_content {
			left: 0;

			&:before {
			  left: 0;

				@include onlyMobile() {
				  left: 30px;
				}
			}
		}
	}

	&:nth-child(odd) {
		.homeMovieCard_image {
			@include onlyWide() {
			  transform: translateX(-15%);
			}
		}

		.homeMovieCard_content {
			right: 0;

			@include onlyDesktop() {
				text-align: right;
			}

			&:before {
			  right: 0;

				@include onlyMobile() {
				  right: auto;
					left: 30px;
				}
			}
		}
	}

	a {
		@include onlyDesktop() {
			display: flex;
		}
	}

	&_image {
		overflow: hidden;
		border-radius: 20px;
		position: relative;
		@include iconfont('play');

		@include onlyDesktop() {
			width: math.div(667, 1092) * 100%;
		}

		&:before {
			font-size: 22px;
			width: 77px;
			height: 77px;
			border-radius: 50%;
			border: 2px solid #fff;
			position: absolute;
			z-index: 2;
			color: $colorBlack;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #fff;
			@include center;

			@include onlyWide() {
			  font-size: 29px;
				width: 100px;
				height: 100px;
			}

			@include onlyMobile() {
			  width: 50px;
				height: 50px;
				font-size: 15px;
			}
		}

		&:after {
		  content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			background-color: rgba(0, 0, 0, .1);
		}

		@include onlyWide() {
			width: math.div(860, $widthMain) * 100%;
		}
	}

	&_content {
		width: 100%;
		padding-bottom: 25px;

		@include onlyDesktop() {
			position: absolute;
			bottom: 120px;
			bottom: 90px;
		}

		@include onlyWide() {
			bottom: 120px;
			padding-bottom: 35px;
		}

		@include onlyMobile() {
		  padding-left: 60px;
			padding-bottom: 0;
		}

		&:before {
		  content: '';
			background-color: $colorBlack;
			display: block;
			position: absolute;
			width: math.div(518, 1092) * 100%;
			height: 1px;
			bottom: 0;
			z-index: 1;

			@include onlyWide() {
				width: math.div(668, $widthMain) * 100%;
			}

			@include onlyMobile() {
				width: 1px;
				height: 110px;
			}
		}
	}

	&_title {
		@include onlyMobile() {
		  margin-top: 30px;
		}

	  span {
			display: block;

			&.is-ja {
				font-size: 1.2rem;

				@include onlyWide() {
					font-size: 1.6rem;
				}
			}

			&.is-en {
				margin-top: 20px;
				font-size: 3.4rem;
				@include fontEn;

				@include onlyWide() {
					margin-top: 23px;
					font-size: 4.4rem;
				}

				@include onlyMobile() {
				  font-size: 2.4rem;
					margin-top: 15px;
				}
			}
		}
	}
}



/** fancybox
/**************************************/
.fancybox {
	&-inner {
		position: relative;
		width: 100% !important;
		height: auto !important;
		padding-top: 56.25%;

		iframe {
			position: absolute;
			top: 0;
			right: 0;
			margin: 0 !important;
			width: 100% !important;
			height: 100% !important;

			@include onlyMobile() {
				margin: 0 !important;
			}
		}
	}
}
