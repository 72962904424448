.pickupLists {
	margin-top: 80px;

	@include onlyMobile() {
	  margin-top: 0;
	}
}

.pickupList {
	overflow: hidden;
	border-radius: 20px;
	margin-top: 60px;

	@include onlyMobile() {
	  margin-top: 40px;
	}

	a {
		position: relative;
		@include iconfont('arrowRight');

		@include onlyDesktop() {
			display: flex;
		}

		@include onlyMobile() {
		  display: block;
		}

		&:before {
			font-size: 19px;
			width: 60px;
			height: 60px;
			border-radius: 50%;
			background-color: #fff;
			position: absolute;
			right: 40px;
			bottom: 40px;
			z-index: 1;
			color: $colorBlack;
			display: flex;
			justify-content: center;
			align-items: center;

			@include onlyMobile() {
				font-size: 13px;
			  right: 30px;
				bottom: 30px;
				width: 40px;
				height: 40px;
			}
		}
	}

	&_image {
		position: relative;
		padding-top: 39%;

		@include onlyDesktop() {
		  width: math.div(500, $widthMain) * 100%;
		}

		@include onlyMobile() {
			padding-top: 100%;
		}

	  > div {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: {
			  repeat: no-repeat;
			  position: center center;
			  size: cover;
			}
		}
	}

	&_content {
		display: flex;
		align-items: center;

		@include onlyDesktop() {
		  width: math.div(($widthMain - 500), $widthMain) * 100%;
			padding: 0 10%;
		}

		@include onlyMobile() {
		  padding: 30px 30px 100px 30px;
		}

	  > div {

		}
	}

	&_title {
		font-size: 2.4rem;
		line-height: math.div(43, 24);

		@include onlyMobile() {
		  font-size: 1.8rem;
			line-height: math.div(28.8, 18);
		}
	}

	&_body {
		margin-top: 40px;

		@include onlyMobile() {
		  margin-top: 20px;
		}

	  p {
			font-size: 1.4rem;
			line-height: 2;

			@include onlyMobile() {
				@include clamp(2);
			}
		}
	}
}
