.newsLists {
	margin-top: 80px;

	@include onlyMobile() {
	  margin-top: 40px;
	}
}

.newsList {
	margin-top: 60px;
	padding-top: 60px;
	border-top: 1px solid #e8e8e8;

	@include onlyMobile() {
	  margin-top: 30px;
		padding-top: 30px;
	}

	&:last-child {
		border-bottom: 1px solid #e8e8e8;
		padding-bottom: 60px;

		@include onlyMobile() {
		  padding-bottom: 30px;
		}
	}

	a {
		display: flex;
		justify-content: space-between;

		@include onlyDesktop() {
			align-items: center;
		}
	}

	&_image {
		@include onlyDesktop() {
		  width: math.div(370, $widthMain) * 100%;
		}

		@include onlyMobile() {
		  width: math.div(100, 315) * 100%;
		}
	}

	&_content {
		position: relative;
		@include iconfont('arrowRight');

		&:before {
			font-size: 19px;
			width: 60px;
			height: 60px;
			border-radius: 50%;
			background-color: #fff;
			position: absolute;
			right: 20px;
			top: calc(10% + 20px);
			z-index: 1;
			color: $colorBlack;
			display: flex;
			justify-content: center;
			align-items: center;

			@include onlyMobile() {
			  display: none;
			}
		}

		@include onlyDesktop() {
		  width: math.div(850, $widthMain) * 100%;
			padding-right: 10%;
		}

		@include onlyMobile() {
		  width: math.div(196, 315) * 100%;
		}
	}

	&_date {
		font-size: 1.4rem;
		@include fontEn;

		@include onlyMobile() {
		  font-size: 1.2rem;
		}
	}

	&_title {
		margin-top: 20px;
		font-size: 1.6rem;
		line-height: 2;

		@include onlyMobile() {
			margin-top: 5px;
		  font-size: 1.4rem;
			@include clamp(2);
		}
	}
}
