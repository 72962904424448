.homeGallery {
	margin-bottom: 210px;

	@include onlyMobile() {
	  margin-bottom: 130px;
	}

	&_headline {
		position: absolute;
		width: 80px;

		@include onlyWide() {
		  width: 95px;
		}

		@include onlyMobile() {
		  width: 41px;
		}

	  &[data-position="top"] {
			top: -30px;
			left: 80px;

			@include onlyMobile() {
			  left: 10px;
			}
		}

	  &[data-position="bottom"] {
			right: 80px;
			bottom: -10px;
			transform: rotate(180deg);

			@include onlyMobile() {
			  right: -10px;
				bottom: 80px;
			}
		}
	}

	&_wrap {
		position: relative;
		overflow: hidden;
		z-index: 1;
	}

	&_column {
		display: flex;
		flex-wrap: wrap;

		@include onlyDesktop() {
			width: 50%;
			float: left;
		}

		&:nth-child(2) {
			@include onlyDesktop() {
				margin-top: 120px;
			}
		}
	}

	&_item {
	  &[data-size="large"] {
			width: 100%;
		}

		&[data-size="small"] {
			width: 50%;
		}

		a {
			display: block;
		}
	}

	&_more {
		display: flex;
		justify-content: center;
		margin-top: 100px;

		@include onlyMobile() {
		  margin-top: 70px;
		}
	}
}
