.itemCarousel {
  position: relative;
  padding-bottom: 75px;

  &_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_slider {
    cursor: grab;

    @include onlyDesktop() {
      margin-top: 65px;
      padding-bottom: 90px !important;

    }

    @include onlyMobile() {
      padding-bottom: 35px !important;
    }
  }

  &_control {
    position: relative;
    display: flex;

    @include onlyMobile() {
      display: none;
    }
  }

  &_container {
    margin-top: 80px;
  }

  &_bar {
    > div {
      position: relative;
    }
  }

  &_button {
    @include onlyDesktop() {
      margin-top: 80px;
    }

    @include onlyMobile() {
      margin-top: 40px;
    }
  }
}



/** swiper
/**************************************/
.itemCarousel {
  .swiper {
    &-container {
      overflow: visible;
    }

    &-scrollbar {
      position: relative;
      -ms-touch-action: none;
      background: #f2f2f2;
      position: absolute;
      left: .1%;
      left: 0;
      bottom: 70px;
      z-index: 2;
      height: 2px;

      @include onlyDesktop() {
        width: calc(80vw - 17px);
      }

      @include onlyMobile() {
        width: 100%;
      }
    }

    &-scrollbar-drag {
      height: 100%;
      width: 100%;
      position: relative;
      background: $colorMain;
      left: 0;
      top: 0;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: -20px;
        height: 40px;
        width: 100%;
        cursor: move;
      }
    }

    &-button-next,
    &-button-prev {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      font-size: 10px;
      cursor: pointer;
      background-color: $colorMain;
      @include iconfont('arrowRight');
      @include transition(.6s $easeOutQuart);

      @include onlyDesktop() {
        width: 70px;
        height: 70px;
      }

      @include onlyMobile() {
        width: 42px;
        height: 42px;
      }

      &:before {
        color: #fff;
        font-size: 19px;
      }

      &:hover {
        @include onlyDesktop() {
          border-color: #4a4a4a;
        }
      }

      &:focus {
        outline: none;
      }
    }

    &-button-prev {
      margin-right: 20px;

      &:before {
        transform: scale(-1, 1);
      }
    }
  }
}
