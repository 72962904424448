/** display
/**************************************/
.inlineDesktop {
  display: none !important;

  @include onlyDesktop {
    display: inline !important;
  }
}

.inlineMobile {
  display: inline !important;

  @include onlyDesktop {
    display: none !important;
  }
}

.inlineBlockDesktop {
  display: none !important;

  @include onlyDesktop {
    display: inline-block !important;
  }
}

.inlineBlockMobile {
  display: inline-block !important;

  @include onlyDesktop {
    display: none !important;
  }
}

.blockDesktop {
  display: block !important;

  @include onlyMobile() {
    display: none !important;
  }
}

.blockMobile {
  display: none !important;

  @include onlyMobile() {
    display: block !important;
  }
}

.flexDesktop {
  display: none !important;

  @include onlyDesktop {
    display: flex !important;
  }
}

.flexMobile {
  display: flex !important;

  @include onlyDesktop {
    display: none !important;
  }
}
