@font-face {
  font-family: 'iconfont';
  src: url('../font/iconfont.eot?1649741933379');
  src: url('../font/iconfont.eot?1649741933379#iefix') format('eot'),
    url('../font/iconfont.woff2?1649741933379') format('woff2'),
    url('../font/iconfont.woff?1649741933379') format('woff'),
    url('../font/iconfont.ttf?1649741933379') format('truetype'),
    url('../font/iconfont.svg?1649741933379#iconfont') format('svg');
}

@mixin iconfont-styles {
  font-family: 'iconfont';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
}

%iconfont {
  @include iconfont-styles;
}

@function iconfont-char($filename) {
  $char: '';

  @if $filename == arrowRight {
    $char: '\EA01';
  }
  @if $filename == cart {
    $char: '\EA02';
  }
  @if $filename == favorite {
    $char: '\EA03';
  }
  @if $filename == instagram {
    $char: '\EA04';
  }
  @if $filename == mypage {
    $char: '\EA05';
  }
  @if $filename == play {
    $char: '\EA06';
  }
  @if $filename == twitter {
    $char: '\EA07';
  }
  @if $filename == youtube {
    $char: '\EA08';
  }

  @return $char;
}

@mixin iconfont($filename, $insert: before, $extend: true) {
  &:#{$insert} {
    @if $extend {
      @extend %iconfont;
    } @else {
      @include iconfont-styles;
    }
    content: iconfont-char($filename);
  }
}

// .iconfont--arrowRight {
//   @include iconfont(arrowRight);
// }
// .iconfont--cart {
//   @include iconfont(cart);
// }
// .iconfont--favorite {
//   @include iconfont(favorite);
// }
// .iconfont--instagram {
//   @include iconfont(instagram);
// }
// .iconfont--mypage {
//   @include iconfont(mypage);
// }
// .iconfont--play {
//   @include iconfont(play);
// }
// .iconfont--twitter {
//   @include iconfont(twitter);
// }
// .iconfont--youtube {
//   @include iconfont(youtube);
// }
// 