.homeImage {
	height: 800px;
	margin-top: 310px;
	position: relative;

	@include onlyMobile() {
	  height: 100vw;
		margin-top: 140px;
	}

	&_media {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: {
		  repeat: no-repeat;
		  position: center center;
		  size: cover;
		}
	}
}

.snake {
	position: absolute;
	top: -100px;
	left: 50%;
	position: absolute;
	transform: translateX(-50%);
	z-index: 5;
	min-width: 1000px;
	pointer-events: none;
	width: 100vw;

	svg {
		g {
			path {
				fill: none;
				stroke: transparent;
				stroke-miterlimit: 10;
				stroke-width: 2px
			}
		}
	}

	p {
		color: #fff;
		position: absolute;
	}
}



.snake {
	min-width: 1000px;
	pointer-events: none;
	width: 100vw;
	position: relative;
	z-index: 1;

	g {
		fill: none;
		stroke: transparent;
		stroke-miterlimit: 10;
		stroke-width: 2px
	}

	&_text {
	  color: #fff;
    position: absolute;

		.char,
		.whitespace {
			color: $colorMain;
	    display: inline-block;
	    font-size: 5rem;
	    position: absolute;
	    width: .7em;
			@include fontEn;

			@include onlyMobile() {
			  font-size: 3rem;
			}
		}

		.char {
			&[data-char="/"] {
				color: transparent;
			}
		}

		.whitespace {
			width: .4em;
		}
	}
}
