/** unmounting in html5 */
/**************************************/
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@200&display=swap");
address, article, aside, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

[hidden], command, datalist, menu[type=context], rp, source {
  display: none;
}

/** document */
/**************************************/
* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1;
  background: #fff;
  font-family: sans-serif;
  color: #484848;
  text-align: left;
  margin: 0;
  padding: 0;
}

/** h1 h2 h3 h4 h5 */
/**************************************/
h1, h2, h3, h4, h5 {
  font-weight: normal;
  margin: 0;
  padding: 0;
}

/** ul ol dl */
/**************************************/
ul, ol, dl {
  margin: 0;
  padding: 0;
  list-style: none;
}

dd {
  margin: 0;
}

/** p */
/**************************************/
p {
  line-height: 2;
  margin: 0;
  padding: 0;
}

/** tables */
/**************************************/
table {
  border-spacing: 0;
  border-collapse: collapse;
  font-size: inherit;
}
table > caption {
  text-align: left;
}
table th {
  text-align: left;
  margin: 0;
  padding: 0;
}
table td {
  margin: 0;
  padding: 0;
}

/** form */
/**************************************/
form {
  margin: 0;
  padding: 0;
}

input, select, textarea {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
  padding: 0;
}

/** images */
/**************************************/
img, picture {
  border: none;
  display: block;
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
}

/** anchor */
/**************************************/
a {
  outline: none;
  text-decoration: none;
  color: #484848;
}

/** pre */
/**************************************/
pre {
  border: 1px solid #bfbfbf;
  padding: 5px;
}

/** display
/**************************************/
.inlineDesktop {
  display: none !important;
}
@media only screen and (min-width: 901px) {
  .inlineDesktop {
    display: inline !important;
  }
}

.inlineMobile {
  display: inline !important;
}
@media only screen and (min-width: 901px) {
  .inlineMobile {
    display: none !important;
  }
}

.inlineBlockDesktop {
  display: none !important;
}
@media only screen and (min-width: 901px) {
  .inlineBlockDesktop {
    display: inline-block !important;
  }
}

.inlineBlockMobile {
  display: inline-block !important;
}
@media only screen and (min-width: 901px) {
  .inlineBlockMobile {
    display: none !important;
  }
}

.blockDesktop {
  display: block !important;
}
@media only screen and (max-width: 900px) {
  .blockDesktop {
    display: none !important;
  }
}

.blockMobile {
  display: none !important;
}
@media only screen and (max-width: 900px) {
  .blockMobile {
    display: block !important;
  }
}

.flexDesktop {
  display: none !important;
}
@media only screen and (min-width: 901px) {
  .flexDesktop {
    display: flex !important;
  }
}

.flexMobile {
  display: flex !important;
}
@media only screen and (min-width: 901px) {
  .flexMobile {
    display: none !important;
  }
}

@font-face {
  font-family: "iconfont";
  src: url("../font/iconfont.eot?1649741933379");
  src: url("../font/iconfont.eot?1649741933379#iefix") format("eot"), url("../font/iconfont.woff2?1649741933379") format("woff2"), url("../font/iconfont.woff?1649741933379") format("woff"), url("../font/iconfont.ttf?1649741933379") format("truetype"), url("../font/iconfont.svg?1649741933379#iconfont") format("svg");
}
.homeMovieCard_image:before, .textLink[data-icon-prev=instagram] a:after, .textLink[data-icon-next=arrowRight] a:after, .pickupList a:before, .newsList_content:before, .itemCarousel .swiper-button-next:before, .itemCarousel .swiper-button-prev:before, .bottomMenu_button a:before, .headerConfig_item[data-config=cart] a:before, .headerConfig_item[data-config=favorite] a:before, .headerConfig_item[data-config=mypage] a:before, .globalMenuSocial_item[data-social=instagram] a:before, .globalMenuSocial_item[data-social=twitter] a:before, .globalMenuSocial_item[data-social=youtube] a:before, .globalMenuConfig_item[data-config=cart] a:before, .globalMenuConfig_item[data-config=favorite] a:before, .globalMenuConfig_item[data-config=mypage] a:before, .footer_config > div > a[data-config=cart]:before, .footer_config > div > a[data-config=favorite]:before, .footer_config > div > a[data-config=mypage]:before, .footer_social a[data-social=instagram]:before, .footer_social a[data-social=twitter]:before, .footer_social a[data-social=youtube]:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
}

body {
  font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic", Verdana, Meiryo, sans-serif;
  font-style: normal;
  -webkit-text-size-adjust: none;
}

body.compensate-for-scrollbar {
  overflow: hidden;
}

.fancybox-active {
  height: auto;
}

.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute !important;
  top: -9999px;
  visibility: hidden;
}

.fancybox-container {
  -webkit-backface-visibility: hidden;
  height: 100%;
  left: 0;
  outline: none;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  transform: translateZ(0);
  width: 100%;
  z-index: 99992;
}

.fancybox-container * {
  box-sizing: border-box;
}

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}

.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71);
}

.fancybox-is-open .fancybox-bg {
  opacity: 0.9;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
}

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
  direction: ltr;
  opacity: 0;
  position: absolute;
  transition: opacity 0.25s ease, visibility 0s ease 0.25s;
  visibility: hidden;
  z-index: 99997;
}

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption,
.fancybox-show-nav .fancybox-navigation .fancybox-button {
  opacity: 1;
  transition: opacity 0.25s ease 0s, visibility 0s ease 0s;
  visibility: visible;
}

.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fancybox-toolbar {
  right: 0;
  top: 0;
}

.fancybox-stage {
  direction: ltr;
  overflow: visible;
  transform: translateZ(0);
  z-index: 99994;
}

.fancybox-is-open .fancybox-stage {
  overflow: hidden;
}

.fancybox-slide {
  -webkit-backface-visibility: hidden;
  /* Using without prefix would break IE11 */
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: transform, opacity;
  white-space: normal;
  width: 100%;
  z-index: 99994;
}

.fancybox-slide::before {
  content: "";
  display: inline-block;
  font-size: 0;
  height: 100%;
  vertical-align: middle;
  width: 0;
}

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
  display: block;
}

.fancybox-slide--image {
  overflow: hidden;
  padding: 44px 0;
}

.fancybox-slide--image::before {
  display: none;
}

.fancybox-slide--html {
  padding: 6px;
}

.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0;
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: relative;
  text-align: left;
  vertical-align: middle;
}

.fancybox-slide--image .fancybox-content {
  animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  -webkit-backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  -ms-transform-origin: top left;
  transform-origin: top left;
  transition-property: transform, opacity;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99995;
}

.fancybox-can-zoomOut .fancybox-content {
  cursor: zoom-out;
}

.fancybox-can-zoomIn .fancybox-content {
  cursor: zoom-in;
}

.fancybox-can-swipe .fancybox-content,
.fancybox-can-pan .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab;
}

.fancybox-is-grabbing .fancybox-content {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.fancybox-container [data-selectable=true] {
  cursor: text;
}

.fancybox-image,
.fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.fancybox-spaceball {
  z-index: 1;
}

.fancybox-slide--video .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--pdf .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
  height: 100%;
  overflow: visible;
  padding: 0;
  width: 100%;
}

.fancybox-slide--video .fancybox-content {
  background: #000;
}

.fancybox-slide--map .fancybox-content {
  background: #e5e3df;
}

.fancybox-slide--iframe .fancybox-content {
  background: #fff;
}

.fancybox-video,
.fancybox-iframe {
  background: transparent;
  border: 0;
  display: block;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

/* Fix iOS */
.fancybox-iframe {
  left: 0;
  position: absolute;
  top: 0;
}

.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%;
}

.fancybox-error p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0;
}

/* Buttons */
.fancybox-button {
  background: rgba(30, 30, 30, 0.6);
  border: 0;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  padding: 10px;
  position: relative;
  transition: color 0.2s;
  vertical-align: top;
  visibility: inherit;
  width: 44px;
}

.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
  color: #ccc;
}

.fancybox-button:hover {
  color: #fff;
}

.fancybox-button:focus {
  outline: none;
}

.fancybox-button.fancybox-focus {
  outline: 1px dotted;
}

.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
  color: #888;
  cursor: default;
  outline: none;
}

/* Fix IE11 */
.fancybox-button div {
  height: 100%;
}

.fancybox-button svg {
  display: block;
  height: 100%;
  overflow: visible;
  position: relative;
  width: 100%;
}

.fancybox-button svg path {
  fill: currentColor;
  stroke-width: 0;
}

.fancybox-button--play svg:nth-child(2),
.fancybox-button--fsenter svg:nth-child(2) {
  display: none;
}

.fancybox-button--pause svg:nth-child(1),
.fancybox-button--fsexit svg:nth-child(1) {
  display: none;
}

.fancybox-progress {
  background: #ff5268;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -ms-transform-origin: 0;
  transform-origin: 0;
  transition-property: transform;
  transition-timing-function: linear;
  z-index: 99998;
}

/* Close button on the top right corner of html content */
.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #ccc;
  cursor: pointer;
  opacity: 0.8;
  padding: 8px;
  position: absolute;
  right: -12px;
  top: -44px;
  z-index: 401;
}

.fancybox-close-small:hover {
  color: #fff;
  opacity: 1;
}

.fancybox-slide--html .fancybox-close-small {
  color: currentColor;
  padding: 10px;
  right: 0;
  top: 0;
}

.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
  overflow: hidden;
}

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
  display: none;
}

/* Navigation arrows */
.fancybox-navigation .fancybox-button {
  background-clip: content-box;
  height: 100px;
  opacity: 0;
  position: absolute;
  top: calc(50% - 50px);
  width: 70px;
}

.fancybox-navigation .fancybox-button div {
  padding: 7px;
}

.fancybox-navigation .fancybox-button--arrow_left {
  left: 0;
  left: env(safe-area-inset-left);
  padding: 31px 26px 31px 6px;
}

.fancybox-navigation .fancybox-button--arrow_right {
  padding: 31px 6px 31px 26px;
  right: 0;
  right: env(safe-area-inset-right);
}

/* Caption */
.fancybox-caption {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.15) 65%, rgba(0, 0, 0, 0.075) 75.5%, rgba(0, 0, 0, 0.037) 82.85%, rgba(0, 0, 0, 0.019) 88%, rgba(0, 0, 0, 0) 100%);
  bottom: 0;
  color: #eee;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 75px 44px 25px 44px;
  pointer-events: none;
  right: 0;
  text-align: center;
  z-index: 99996;
}

@supports (padding: 0px) {
  .fancybox-caption {
    padding: 75px max(44px, env(safe-area-inset-right)) max(25px, env(safe-area-inset-bottom)) max(44px, env(safe-area-inset-left));
  }
}
.fancybox-caption--separate {
  margin-top: -50px;
}

.fancybox-caption__body {
  max-height: 50vh;
  overflow: auto;
  pointer-events: all;
}

.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none;
}

.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline;
}

/* Loading indicator */
.fancybox-loading {
  animation: fancybox-rotate 1s linear infinite;
  background: transparent;
  border: 4px solid #888;
  border-bottom-color: #fff;
  border-radius: 50%;
  height: 50px;
  left: 50%;
  margin: -25px 0 0 -25px;
  opacity: 0.7;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 50px;
  z-index: 99999;
}

@keyframes fancybox-rotate {
  100% {
    transform: rotate(360deg);
  }
}
/* Transition effects */
.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
}

/* transitionEffect: slide */
.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

/* transitionEffect: fade */
.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1;
}

/* transitionEffect: zoom-in-out */
.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(1.5, 1.5, 1.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

/* transitionEffect: rotate */
.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  -ms-transform: rotate(-360deg);
  transform: rotate(-360deg);
}

.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

/* transitionEffect: circular */
.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
}

/* transitionEffect: tube */
.fancybox-fx-tube.fancybox-slide--previous {
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
}

.fancybox-fx-tube.fancybox-slide--next {
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
}

.fancybox-fx-tube.fancybox-slide--current {
  transform: translate3d(0, 0, 0) scale(1);
}

/* Styling for Small-Screen Devices */
@media all and (max-height: 576px) {
  .fancybox-slide {
    padding-left: 6px;
    padding-right: 6px;
  }

  .fancybox-slide--image {
    padding: 6px 0;
  }

  .fancybox-close-small {
    right: -6px;
  }

  .fancybox-slide--image .fancybox-close-small {
    background: #4e4e4e;
    color: #f2f4f6;
    height: 36px;
    opacity: 1;
    padding: 6px;
    right: 0;
    top: 0;
    width: 36px;
  }

  .fancybox-caption {
    padding-left: 12px;
    padding-right: 12px;
  }

  @supports (padding: 0px) {
    .fancybox-caption {
      padding-left: max(12px, env(safe-area-inset-left));
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
}
/* Share */
.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center;
}

.fancybox-share h1 {
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 20px 0;
}

.fancybox-share p {
  margin: 0;
  padding: 0;
}

.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px 5px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  transition: all 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
}

.fancybox-share__button:visited,
.fancybox-share__button:link {
  color: #fff;
}

.fancybox-share__button:hover {
  text-decoration: none;
}

.fancybox-share__button--fb {
  background: #3b5998;
}

.fancybox-share__button--fb:hover {
  background: #344e86;
}

.fancybox-share__button--pt {
  background: #bd081d;
}

.fancybox-share__button--pt:hover {
  background: #aa0719;
}

.fancybox-share__button--tw {
  background: #1da1f2;
}

.fancybox-share__button--tw:hover {
  background: #0d95e8;
}

.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px;
}

.fancybox-share__button svg path {
  fill: #fff;
}

.fancybox-share__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0 0;
  outline: none;
  padding: 10px 15px;
  width: 100%;
}

/* Thumbs */
.fancybox-thumbs {
  background: #ddd;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px 2px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  top: 0;
  width: 212px;
  z-index: 99995;
}

.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden;
}

.fancybox-show-thumbs .fancybox-thumbs {
  display: block;
}

.fancybox-show-thumbs .fancybox-inner {
  right: 212px;
}

.fancybox-thumbs__list {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  position: relative;
  white-space: nowrap;
  width: 100%;
}

.fancybox-thumbs-x .fancybox-thumbs__list {
  overflow: hidden;
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
  width: 7px;
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px;
}

.fancybox-thumbs__list a {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px;
}

.fancybox-thumbs__list a::before {
  border: 6px solid #ff5268;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 99991;
}

.fancybox-thumbs__list a:focus::before {
  opacity: 0.5;
}

.fancybox-thumbs__list a.fancybox-thumbs-active::before {
  opacity: 1;
}

/* Styling for Small-Screen Devices */
@media all and (max-width: 576px) {
  .fancybox-thumbs {
    width: 110px;
  }

  .fancybox-show-thumbs .fancybox-inner {
    right: 110px;
  }

  .fancybox-thumbs__list a {
    max-width: calc(100% - 10px);
  }
}
.footer {
  border-top: 1px solid #E8E8E8;
  padding: 210px 0;
  margin-top: 200px;
}
@media only screen and (max-width: 900px) {
  .footer {
    padding: 60px 0;
    margin-top: 100px;
  }
}
.footer_wrap {
  max-width: 1600px;
  padding-left: 160px;
  padding-right: 160px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 1600px) {
  .footer_wrap {
    padding-left: 10%;
    padding-right: 10%;
  }
}
@media only screen and (max-width: 900px) {
  .footer_wrap {
    padding-left: 8%;
    padding-right: 8%;
  }
}
@media only screen and (min-width: 901px) {
  .footer_wrap {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}
@media only screen and (min-width: 901px) {
  .footer_column:nth-child(1) {
    width: 50%;
    display: flex;
    justify-content: flex-end;
  }
}
@media only screen and (min-width: 901px) {
  .footer_column:nth-child(2) {
    width: 200px;
  }
}
@media only screen and (max-width: 900px) {
  .footer_column:nth-child(2) {
    margin-top: 60px;
  }
}
.footer_logo {
  width: 100%;
}
@media only screen and (max-width: 900px) {
  .footer_logo {
    width: 150px;
    margin: 0 auto;
  }
}
.footer_logo img {
  width: 100%;
}
.footer_social {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 900px) {
  .footer_social {
    justify-content: center;
  }
}
.footer_social a {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #E2528E;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 900px) {
  .footer_social a {
    width: 40px;
    height: 40px;
  }
}
.footer_social a[data-social=youtube]:before {
  content: "\ea08";
}
.footer_social a[data-social=youtube]:before {
  font-size: 28px;
}
@media only screen and (max-width: 900px) {
  .footer_social a[data-social=youtube]:before {
    font-size: 22px;
  }
}
.footer_social a[data-social=twitter]:before {
  content: "\ea07";
}
@media only screen and (max-width: 900px) {
  .footer_social a[data-social=twitter] {
    margin: 0 16px;
  }
}
.footer_social a[data-social=twitter]:before {
  font-size: 20px;
}
@media only screen and (max-width: 900px) {
  .footer_social a[data-social=twitter]:before {
    font-size: 16px;
  }
}
.footer_social a[data-social=instagram]:before {
  content: "\ea04";
}
.footer_social a[data-social=instagram]:before {
  font-size: 20px;
}
@media only screen and (max-width: 900px) {
  .footer_social a[data-social=instagram]:before {
    font-size: 16px;
  }
}
.footer_copyright {
  margin-top: 90px;
  font-size: 1.2rem;
  font-family: "Work Sans", sans-serif;
  letter-spacing: 0.075em;
}
@media only screen and (max-width: 900px) {
  .footer_copyright {
    margin-top: 60px;
    text-align: center;
  }
}
.footer_menu {
  overflow: hidden;
}
@media only screen and (min-width: 901px) {
  .footer_menu {
    width: 66.6666666667%;
    margin-top: -20px;
  }
}
.footer_menu > div {
  width: 50%;
  float: left;
}
.footer_menu > div > a {
  font-size: 1.5rem;
  display: block;
  font-family: "Work Sans", sans-serif;
  letter-spacing: 0.075em;
}
@media only screen and (min-width: 901px) {
  .footer_menu > div > a {
    width: 214px;
    padding: 17.5px 0;
  }
}
@media only screen and (max-width: 900px) {
  .footer_menu > div > a {
    padding: 15px 0;
  }
}
@media only screen and (min-width: 901px) {
  .footer_config {
    width: 33.3333333333%;
  }
}
@media only screen and (max-width: 900px) {
  .footer_config {
    margin-top: 60px;
  }
}
.footer_config > div {
  margin-bottom: 30px;
}
.footer_config > div > a {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #484848;
  padding-bottom: 10px;
}
.footer_config > div > a:before {
  width: 34px;
}
.footer_config > div > a > span {
  font-size: 1.4rem;
  letter-spacing: 0.1em;
}
.footer_config > div > a[data-config=mypage]:before {
  content: "\ea05";
}
.footer_config > div > a[data-config=mypage]:before {
  font-size: 22px;
}
.footer_config > div > a[data-config=favorite]:before {
  content: "\ea03";
}
.footer_config > div > a[data-config=favorite]:before {
  font-size: 22px;
}
.footer_config > div > a[data-config=cart]:before {
  content: "\ea02";
}
.footer_config > div > a[data-config=cart]:before {
  font-size: 25px;
}

.globalMenu {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}
.globalMenu:before {
  content: "";
  background-color: rgba(72, 72, 72, 0.3);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  display: block;
  width: 100%;
  height: 100%;
}
.globalMenu_container {
  width: 500px;
  height: 100%;
  background-color: #E2528E;
  position: relative;
  z-index: 1;
  overflow-y: scroll;
}
@media only screen and (max-width: 900px) {
  .globalMenu_container {
    width: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}
@media only screen and (min-width: 901px) {
  .globalMenu_wrap {
    padding: 100px;
  }
}
@media only screen and (max-width: 900px) {
  .globalMenu_wrap {
    width: 68%;
    margin: 0 auto;
    padding: 100px 0;
  }
}

/** globalMenuMain
/**************************************/
.globalMenuMain_item > a {
  display: block;
  padding: 16px 0;
}
.globalMenuMain_item > a span {
  font-size: 2rem;
  color: #fff;
  font-family: "Work Sans", sans-serif;
  letter-spacing: 0.075em;
}
@media only screen and (max-width: 900px) {
  .globalMenuMain_item > a span {
    font-size: 1.6rem;
  }
}

/** globalMenuConfig
/**************************************/
.globalMenuConfig {
  margin-top: 42px;
}
.globalMenuConfig_item {
  border-bottom: 1px solid #fff;
  padding-bottom: 10px;
}
.globalMenuConfig_item a {
  display: flex;
  align-items: center;
  color: #fff;
  padding: 10px 0 2px;
  margin-top: 20px;
}
.globalMenuConfig_item a span {
  font-size: 1.4rem;
  letter-spacing: 0.1em;
}
.globalMenuConfig_item a:before {
  width: 35px;
}
.globalMenuConfig_item[data-config=mypage] a:before {
  content: "\ea05";
}
.globalMenuConfig_item[data-config=mypage] a:before {
  font-size: 21px;
}
.globalMenuConfig_item[data-config=favorite] a:before {
  content: "\ea03";
}
.globalMenuConfig_item[data-config=favorite] a:before {
  font-size: 22px;
}
.globalMenuConfig_item[data-config=cart] a {
  position: relative;
}
.globalMenuConfig_item[data-config=cart] a:before {
  content: "\ea02";
}
.globalMenuConfig_item[data-config=cart] a:before {
  font-size: 25px;
}
.globalMenuConfig_item[data-config=cart] a i {
  width: 14px;
  height: 14px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  display: block;
  right: -5px;
  top: -5px;
  color: #E2528E;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
}

/** globalMenuSocial
/**************************************/
.globalMenuSocial {
  display: flex;
  align-items: center;
  margin-top: 55px;
}
.globalMenuSocial_item {
  margin-right: 30px;
}
.globalMenuSocial_item:last-child {
  margin-right: 0;
}
.globalMenuSocial_item a {
  color: #fff;
}
.globalMenuSocial_item[data-social=youtube] a:before {
  content: "\ea08";
}
.globalMenuSocial_item[data-social=youtube] a:before {
  font-size: 28px;
}
.globalMenuSocial_item[data-social=twitter] a:before {
  content: "\ea07";
}
.globalMenuSocial_item[data-social=twitter] a:before {
  font-size: 20px;
}
.globalMenuSocial_item[data-social=instagram] a:before {
  content: "\ea04";
}
.globalMenuSocial_item[data-social=instagram] a:before {
  font-size: 20px;
}

/** animation
/**************************************/
.globalMenu {
  visibility: hidden;
  pointer-events: none;
}
.show-menu .globalMenu {
  visibility: visible;
  pointer-events: auto;
}
.globalMenu:before {
  opacity: 0;
  -webkit-transition: 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  -moz-transition: 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  -o-transition: 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  transition: 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
}
.show-menu .globalMenu:before {
  opacity: 1;
}
.globalMenu_container {
  transform: translateX(100%);
  -webkit-transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  -moz-transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  -o-transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
}
.show-menu .globalMenu_container {
  transform: translateX(0);
}

/** globalMenuChild
/**************************************/
.globalMenuChild {
  padding-left: 30px;
}
@media only screen and (max-width: 900px) {
  .globalMenuChild {
    padding-left: 20px;
  }
}
.globalMenuChild > a {
  display: block;
  color: #fff;
  padding: 10px 0;
}
.globalMenuChild > a > span {
  font-size: 1.6rem;
  font-family: "Work Sans", sans-serif;
  letter-spacing: 0.075em;
}
@media only screen and (max-width: 900px) {
  .globalMenuChild > a > span {
    font-size: 1.4rem;
  }
}
.globalMenuChild > a:last-child {
  margin-bottom: 20px;
}

.header {
  position: absolute;
  height: 80px;
  right: 0;
  top: 0;
  background-color: #E2528E;
  border-bottom-left-radius: 10px;
}
@media only screen and (max-width: 900px) {
  .header {
    display: none;
  }
}
.header_wrap {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 100px 0 40px;
}
.header_menu {
  display: flex;
}
.header_menu a {
  display: block;
  margin-right: 30px;
}
.header_menu a span {
  font-size: 1.5rem;
  color: #fff;
  font-family: "Work Sans", sans-serif;
  letter-spacing: 0.075em;
}

/** headerConfig
/**************************************/
.headerConfig {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.headerConfig_item {
  margin-right: 20px;
}
.headerConfig_item a {
  color: #fff;
  display: block;
}
.headerConfig_item[data-config=mypage] a:before {
  content: "\ea05";
}
.headerConfig_item[data-config=mypage] a:before {
  font-size: 21px;
}
.headerConfig_item[data-config=favorite] a:before {
  content: "\ea03";
}
.headerConfig_item[data-config=favorite] a:before {
  font-size: 22px;
}
.headerConfig_item[data-config=cart] a {
  position: relative;
}
.headerConfig_item[data-config=cart] a:before {
  content: "\ea02";
}
.headerConfig_item[data-config=cart] a:before {
  font-size: 25px;
}
.headerConfig_item[data-config=cart] a i {
  width: 14px;
  height: 14px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  display: block;
  right: -5px;
  top: -5px;
  color: #E2528E;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
}

body {
  background-color: #FBF8F8;
}
body:before {
  content: "";
  display: block;
  background-color: #FBF8F8;
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
}
body[data-state=loaded]:before {
  transition: 0.6s cubic-bezier(0.39, 0.575, 0.565, 1);
  opacity: 0;
}
body.show-menu {
  overflow: hidden;
}
body[data-state=preload] * {
  transition: none !important;
}

.container {
  overflow: hidden;
}

.section {
  margin-top: 180px;
}
@media only screen and (max-width: 900px) {
  .section {
    margin-top: 120px;
  }
}
.section_wrap {
  position: relative;
  max-width: 1600px;
  padding-left: 160px;
  padding-right: 160px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 1600px) {
  .section_wrap {
    padding-left: 10%;
    padding-right: 10%;
  }
}
@media only screen and (max-width: 900px) {
  .section_wrap {
    padding-left: 8%;
    padding-right: 8%;
  }
}
.section_more {
  display: flex;
  justify-content: flex-end;
  margin-top: 80px;
}
@media only screen and (max-width: 900px) {
  .section_more {
    margin-top: 60px;
  }
}
.itemCarousel + .section_more {
  margin-top: 10px;
}
@media only screen and (max-width: 900px) {
  .itemCarousel + .section_more {
    margin-top: 0;
  }
}
.seriesLists + .section_more {
  margin-top: 50px;
}

.header {
  z-index: 10;
}

.globalMenu {
  z-index: 20;
}

.trigger {
  z-index: 30;
}

body:before {
  z-index: 100;
}

.banner {
  margin-top: 180px;
  max-width: 1600px;
  padding-left: 160px;
  padding-right: 160px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 1600px) {
  .banner {
    padding-left: 10%;
    padding-right: 10%;
  }
}
@media only screen and (max-width: 900px) {
  .banner {
    padding-left: 8%;
    padding-right: 8%;
  }
}
@media only screen and (max-width: 900px) {
  .banner {
    padding: 0;
    margin-top: 130px;
  }
}
.banner_item {
  margin-top: 60px;
}
@media only screen and (max-width: 900px) {
  .banner_item {
    margin-top: 30px;
  }
}
.banner_item:first-child {
  margin-top: 0;
}
.banner_item a {
  display: block;
  text-align: center;
}
.banner_item a img {
  display: inline-block;
}

.bottomMenu {
  max-width: 1600px;
  padding-left: 160px;
  padding-right: 160px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 1600px) {
  .bottomMenu {
    padding-left: 10%;
    padding-right: 10%;
  }
}
@media only screen and (max-width: 900px) {
  .bottomMenu {
    padding-left: 8%;
    padding-right: 8%;
  }
}
@media only screen and (min-width: 901px) {
  .bottomMenu_wrap {
    display: flex;
    justify-content: space-between;
  }
}
@media only screen and (min-width: 901px) {
  .bottomMenu_button {
    width: 46.875%;
  }
}
.bottomMenu_button a {
  height: 202px;
  border-radius: 101px;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #fff;
  padding-left: 13%;
}
.bottomMenu_button a:before {
  content: "\ea01";
}
@media only screen and (min-width: 1600px) {
  .bottomMenu_button a {
    height: 238px;
    border-radius: 119px;
  }
}
@media only screen and (max-width: 900px) {
  .bottomMenu_button a {
    height: 124px;
    border-radius: 64px;
    margin-top: 30px;
  }
}
.bottomMenu_button a:before {
  font-size: 19px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  right: 9%;
  top: calc(50% - 35px);
  z-index: 1;
  color: #484848;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottomMenu_title {
  font-size: 3.2rem;
  font-family: "Work Sans", sans-serif;
  letter-spacing: 0.075em;
}
@media only screen and (max-width: 900px) {
  .bottomMenu_title {
    font-size: 1.8rem;
  }
}

/** headlineLv1
/**************************************/
.headlineLv1 span {
  font-size: 4.4rem;
  font-family: "Work Sans", sans-serif;
  letter-spacing: 0.075em;
}
@media only screen and (max-width: 900px) {
  .headlineLv1 span {
    font-size: 2.4rem;
  }
}

.itemCard:last-child {
  margin-right: 0;
}
.itemCard a {
  display: block;
}
@media only screen and (min-width: 901px) {
  .itemCard a:hover .itemCard_image > div {
    transform: scale(1.06);
  }
  .itemCard a:hover .itemCard_image:before {
    height: 75%;
  }
  .itemCard a:hover .itemCard_imageSquare > div {
    transform: scale(1.06);
  }
  .itemCard a:hover .itemCard_imageSquare:before {
    height: 75%;
  }
}
.itemCard_image {
  position: relative;
  /*&:before {
    content: '';
    width: 100%;
    height: 95%;
    display: block;
    position: absolute;
    left: 0;
    top: -40px;
    z-index: 0;
    @include transition(.6s $easeOutCirc);
  }*/
}
.itemCard_image > div {
  position: relative;
  z-index: 1;
  text-align: center;
  margin: 0 auto;
  text-align: center;
  height: 264px;
  -webkit-transition: 0.9s cubic-bezier(0.165, 0.84, 0.44, 1);
  -moz-transition: 0.9s cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: 0.9s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: 0.9s cubic-bezier(0.165, 0.84, 0.44, 1);
}
@media only screen and (max-width: 900px) {
  .itemCard_image > div {
    height: 200px;
  }
}
.itemCard_image > div img {
  width: auto;
  max-width: none;
  height: 100%;
  display: inline-block;
}
.itemCard_content {
  text-align: center;
}
@media only screen and (min-width: 901px) {
  .itemCard_content {
    margin-top: 55px;
  }
}
@media only screen and (max-width: 900px) {
  .itemCard_content {
    margin-top: 30px;
  }
}
.itemCard_title {
  font-family: "Work Sans", sans-serif;
  letter-spacing: 0.075em;
}
@media only screen and (min-width: 901px) {
  .itemCard_title {
    font-size: 2rem;
  }
}
@media only screen and (max-width: 900px) {
  .itemCard_title {
    font-size: 1.6rem;
  }
}
.itemCard_model {
  font-family: "Work Sans", sans-serif;
  letter-spacing: 0.075em;
}
@media only screen and (min-width: 901px) {
  .itemCard_model {
    font-size: 1.4rem;
    margin-top: 7px;
  }
}
@media only screen and (max-width: 900px) {
  .itemCard_model {
    font-size: 1.2rem;
  }
}
.itemCard_price {
  font-family: "Work Sans", sans-serif;
  letter-spacing: 0.075em;
}
@media only screen and (min-width: 901px) {
  .itemCard_price {
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 900px) {
  .itemCard_price {
    font-size: 1.4rem;
  }
}
.itemCard_price span {
  font-size: 1.2rem;
}
@media only screen and (max-width: 900px) {
  .itemCard_price span {
    font-size: 1rem;
  }
}

/** animation
/**************************************/
.itemCarousel .itemCard > a {
  -webkit-transition: 1.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  -moz-transition: 1.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: 1.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: 1.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.itemCarousel.inView .itemCard:nth-child(1) a {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 0.4s;
}
.itemCarousel.inView .itemCard:nth-child(2) a {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 0.6s;
}
.itemCarousel.inView .itemCard:nth-child(3) a {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 0.8s;
}
.itemCarousel.inView .itemCard:nth-child(4) a {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 1s;
}
.itemCarousel.inView .itemCard:nth-child(5) a {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 1.2s;
}
.itemCarousel.inView .itemCard:nth-child(6) a {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 1.4s;
}
.itemCarousel.inView .itemCard:nth-child(7) a {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 1.6s;
}
.itemCarousel.inView .itemCard:nth-child(8) a {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 1.8s;
}
.itemCarousel.inView .itemCard:nth-child(9) a {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 2s;
}
.itemCarousel.inView .itemCard:nth-child(10) a {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 2.2s;
}
.itemCarousel.inView .itemCard:nth-child(11) a {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 2.4s;
}
.itemCarousel.inView .itemCard:nth-child(12) a {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 2.6s;
}
.itemCarousel.inView .itemCard:nth-child(13) a {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 2.8s;
}
.itemCarousel.inView .itemCard:nth-child(14) a {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 3s;
}
.itemCarousel.inView .itemCard:nth-child(15) a {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 3.2s;
}
.itemCarousel.inView .itemCard:nth-child(16) a {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 3.4s;
}
.itemCarousel.inView .itemCard:nth-child(17) a {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 3.6s;
}
.itemCarousel.inView .itemCard:nth-child(18) a {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 3.8s;
}
.itemCarousel.inView .itemCard:nth-child(19) a {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 4s;
}
.itemCarousel.inView .itemCard:nth-child(20) a {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 4.2s;
}
.itemCarousel.inView .itemCard:nth-child(21) a {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 4.4s;
}
.itemCarousel.inView .itemCard:nth-child(22) a {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 4.6s;
}
.itemCarousel.inView .itemCard:nth-child(23) a {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 4.8s;
}
.itemCarousel.inView .itemCard:nth-child(24) a {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 5s;
}
.itemCarousel.inView .itemCard:nth-child(25) a {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 5.2s;
}
.itemCarousel.inView .itemCard:nth-child(26) a {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 5.4s;
}
.itemCarousel.inView .itemCard:nth-child(27) a {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 5.6s;
}
.itemCarousel.inView .itemCard:nth-child(28) a {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 5.8s;
}
.itemCarousel.inView .itemCard:nth-child(29) a {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 6s;
}
.itemCarousel.inView .itemCard:nth-child(30) a {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 6.2s;
}
.itemCarousel.inView .itemCard:nth-child(31) a {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 6.4s;
}

.itemCarousel {
  position: relative;
  padding-bottom: 75px;
}
.itemCarousel_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.itemCarousel_slider {
  cursor: grab;
}
@media only screen and (min-width: 901px) {
  .itemCarousel_slider {
    margin-top: 65px;
    padding-bottom: 90px !important;
  }
}
@media only screen and (max-width: 900px) {
  .itemCarousel_slider {
    padding-bottom: 35px !important;
  }
}
.itemCarousel_control {
  position: relative;
  display: flex;
}
@media only screen and (max-width: 900px) {
  .itemCarousel_control {
    display: none;
  }
}
.itemCarousel_container {
  margin-top: 80px;
}
.itemCarousel_bar > div {
  position: relative;
}
@media only screen and (min-width: 901px) {
  .itemCarousel_button {
    margin-top: 80px;
  }
}
@media only screen and (max-width: 900px) {
  .itemCarousel_button {
    margin-top: 40px;
  }
}

/** swiper
/**************************************/
.itemCarousel .swiper-container {
  overflow: visible;
}
.itemCarousel .swiper-scrollbar {
  position: relative;
  -ms-touch-action: none;
  background: #f2f2f2;
  position: absolute;
  left: 0.1%;
  left: 0;
  bottom: 70px;
  z-index: 2;
  height: 2px;
}
@media only screen and (min-width: 901px) {
  .itemCarousel .swiper-scrollbar {
    width: calc(80vw - 17px);
  }
}
@media only screen and (max-width: 900px) {
  .itemCarousel .swiper-scrollbar {
    width: 100%;
  }
}
.itemCarousel .swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: #E2528E;
  left: 0;
  top: 0;
}
.itemCarousel .swiper-scrollbar-drag:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: -20px;
  height: 40px;
  width: 100%;
  cursor: move;
}
.itemCarousel .swiper-button-next, .itemCarousel .swiper-button-prev {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 10px;
  cursor: pointer;
  background-color: #E2528E;
  -webkit-transition: 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  -moz-transition: 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.itemCarousel .swiper-button-next:before, .itemCarousel .swiper-button-prev:before {
  content: "\ea01";
}
@media only screen and (min-width: 901px) {
  .itemCarousel .swiper-button-next, .itemCarousel .swiper-button-prev {
    width: 70px;
    height: 70px;
  }
}
@media only screen and (max-width: 900px) {
  .itemCarousel .swiper-button-next, .itemCarousel .swiper-button-prev {
    width: 42px;
    height: 42px;
  }
}
.itemCarousel .swiper-button-next:before, .itemCarousel .swiper-button-prev:before {
  color: #fff;
  font-size: 19px;
}
@media only screen and (min-width: 901px) {
  .itemCarousel .swiper-button-next:hover, .itemCarousel .swiper-button-prev:hover {
    border-color: #4a4a4a;
  }
}
.itemCarousel .swiper-button-next:focus, .itemCarousel .swiper-button-prev:focus {
  outline: none;
}
.itemCarousel .swiper-button-prev {
  margin-right: 20px;
}
.itemCarousel .swiper-button-prev:before {
  transform: scale(-1, 1);
}

.newsLists {
  margin-top: 80px;
}
@media only screen and (max-width: 900px) {
  .newsLists {
    margin-top: 40px;
  }
}

.newsList {
  margin-top: 60px;
  padding-top: 60px;
  border-top: 1px solid #e8e8e8;
}
@media only screen and (max-width: 900px) {
  .newsList {
    margin-top: 30px;
    padding-top: 30px;
  }
}
.newsList:last-child {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 60px;
}
@media only screen and (max-width: 900px) {
  .newsList:last-child {
    padding-bottom: 30px;
  }
}
.newsList a {
  display: flex;
  justify-content: space-between;
}
@media only screen and (min-width: 901px) {
  .newsList a {
    align-items: center;
  }
}
@media only screen and (min-width: 901px) {
  .newsList_image {
    width: 28.90625%;
  }
}
@media only screen and (max-width: 900px) {
  .newsList_image {
    width: 31.746031746%;
  }
}
.newsList_content {
  position: relative;
}
.newsList_content:before {
  content: "\ea01";
}
.newsList_content:before {
  font-size: 19px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  right: 20px;
  top: calc(10% + 20px);
  z-index: 1;
  color: #484848;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 900px) {
  .newsList_content:before {
    display: none;
  }
}
@media only screen and (min-width: 901px) {
  .newsList_content {
    width: 66.40625%;
    padding-right: 10%;
  }
}
@media only screen and (max-width: 900px) {
  .newsList_content {
    width: 62.2222222222%;
  }
}
.newsList_date {
  font-size: 1.4rem;
  font-family: "Work Sans", sans-serif;
  letter-spacing: 0.075em;
}
@media only screen and (max-width: 900px) {
  .newsList_date {
    font-size: 1.2rem;
  }
}
.newsList_title {
  margin-top: 20px;
  font-size: 1.6rem;
  line-height: 2;
}
@media only screen and (max-width: 900px) {
  .newsList_title {
    margin-top: 5px;
    font-size: 1.4rem;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.pickupLists {
  margin-top: 80px;
}
@media only screen and (max-width: 900px) {
  .pickupLists {
    margin-top: 0;
  }
}

.pickupList {
  overflow: hidden;
  border-radius: 20px;
  margin-top: 60px;
}
@media only screen and (max-width: 900px) {
  .pickupList {
    margin-top: 40px;
  }
}
.pickupList a {
  position: relative;
}
.pickupList a:before {
  content: "\ea01";
}
@media only screen and (min-width: 901px) {
  .pickupList a {
    display: flex;
  }
}
@media only screen and (max-width: 900px) {
  .pickupList a {
    display: block;
  }
}
.pickupList a:before {
  font-size: 19px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  right: 40px;
  bottom: 40px;
  z-index: 1;
  color: #484848;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 900px) {
  .pickupList a:before {
    font-size: 13px;
    right: 30px;
    bottom: 30px;
    width: 40px;
    height: 40px;
  }
}
.pickupList_image {
  position: relative;
  padding-top: 39%;
}
@media only screen and (min-width: 901px) {
  .pickupList_image {
    width: 39.0625%;
  }
}
@media only screen and (max-width: 900px) {
  .pickupList_image {
    padding-top: 100%;
  }
}
.pickupList_image > div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.pickupList_content {
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 901px) {
  .pickupList_content {
    width: 60.9375%;
    padding: 0 10%;
  }
}
@media only screen and (max-width: 900px) {
  .pickupList_content {
    padding: 30px 30px 100px 30px;
  }
}
.pickupList_title {
  font-size: 2.4rem;
  line-height: 1.7916666667;
}
@media only screen and (max-width: 900px) {
  .pickupList_title {
    font-size: 1.8rem;
    line-height: 1.6;
  }
}
.pickupList_body {
  margin-top: 40px;
}
@media only screen and (max-width: 900px) {
  .pickupList_body {
    margin-top: 20px;
  }
}
.pickupList_body p {
  font-size: 1.4rem;
  line-height: 2;
}
@media only screen and (max-width: 900px) {
  .pickupList_body p {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.rotationLogo {
  margin-top: 120px;
  max-width: 1600px;
  padding-left: 160px;
  padding-right: 160px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 1600px) {
  .rotationLogo {
    padding-left: 10%;
    padding-right: 10%;
  }
}
@media only screen and (max-width: 900px) {
  .rotationLogo {
    padding-left: 8%;
    padding-right: 8%;
  }
}
@media only screen and (min-width: 901px) {
  .rotationLogo {
    display: flex;
    justify-content: flex-end;
  }
}
@media only screen and (max-width: 900px) {
  .rotationLogo {
    margin-top: 80px;
  }
}
.rotationLogo_content {
  margin-right: 80px;
  width: 220px;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
@media only screen and (max-width: 900px) {
  .rotationLogo_content {
    width: 150px;
    height: 150px;
  }
}
.rotationLogo_core {
  position: absolute;
}
@media only screen and (max-width: 900px) {
  .rotationLogo_core {
    width: 49px;
  }
}
.rotationLogo_frame {
  position: absolute;
  left: 0;
  top: 0;
  animation: 30s linear infinite rotationLogo;
}

@keyframes rotationLogo {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media only screen and (min-width: 901px) {
  .seriesLists {
    display: flex;
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 900px) {
  .seriesLists {
    margin-top: 40px;
  }
}

.seriesList {
  position: relative;
}
@media only screen and (min-width: 901px) {
  .seriesList {
    width: 44.53125%;
  }
  .seriesList:nth-child(odd) {
    margin-right: 10.9375%;
    margin-top: 100px;
  }
}
@media only screen and (max-width: 900px) {
  .seriesList {
    margin-top: 40px;
    padding-right: 20px;
  }
}
.seriesList a {
  display: block;
  border-radius: 20px;
  overflow: hidden;
}
.seriesList_label {
  position: absolute;
  right: -35px;
  top: 0;
  line-height: 1;
  z-index: 2;
  height: 100%;
}
@media only screen and (max-width: 900px) {
  .seriesList_label {
    right: 0;
  }
}
.seriesList_label span {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  line-height: 1;
  width: 100%;
  writing-mode: vertical-rl;
  font-family: "Work Sans", sans-serif;
  letter-spacing: 0.075em;
}
@media only screen and (min-width: 901px) {
  .seriesList_label span {
    font-size: 1.6rem;
  }
}
@media only screen and (max-width: 900px) {
  .seriesList_label span {
    font-size: 1rem;
  }
  .ios .seriesList_label span {
    right: 6px;
  }
}
.seriesList_image {
  padding-top: 75%;
  position: relative;
}
@media only screen and (max-width: 900px) {
  .seriesList_image {
    padding-top: 69.9%;
  }
}
.seriesList_image > div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.seriesList_content {
  background-color: #fff;
  display: flex;
  justify-content: center;
  padding: 40px 0;
}
@media only screen and (max-width: 900px) {
  .seriesList_content {
    padding: 20px 0;
  }
}
.seriesList_content > div {
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 901px) {
  .seriesList_products {
    margin-right: 34px;
    height: 200px;
  }
}
@media only screen and (max-width: 900px) {
  .seriesList_products {
    margin-right: 20px;
    height: 80px;
  }
}
.seriesList_products img {
  width: auto;
  height: 100%;
  max-width: none;
}
.seriesList_title span {
  display: block;
}
.seriesList_title span.is-en {
  font-size: 3.6rem;
  font-family: "Work Sans", sans-serif;
  letter-spacing: 0.075em;
}
@media only screen and (max-width: 900px) {
  .seriesList_title span.is-en {
    font-size: 1.8rem;
  }
}
.seriesList_title span.is-ja {
  margin-top: 20px;
  font-size: 1.6rem;
}
@media only screen and (max-width: 900px) {
  .seriesList_title span.is-ja {
    margin-top: 10px;
    font-size: 1rem;
  }
}

.textLink a {
  padding-bottom: 15px;
  position: relative;
}
.textLink a span {
  font-size: 1.6rem;
  font-family: "Work Sans", sans-serif;
  letter-spacing: 0.075em;
}
@media only screen and (max-width: 900px) {
  .textLink a span {
    font-size: 1.4rem;
  }
}
.textLink a:before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #484848;
  display: block;
  content: "";
}
.textLink[data-icon-next=arrowRight] a:after {
  content: "\ea01";
}
.textLink[data-icon-next=arrowRight] a:after {
  font-size: 19px;
}
.textLink[data-icon-prev=instagram] a:after {
  content: "\ea04";
}
.textLink[data-icon-prev=instagram] a:after {
  font-size: 20px;
}
.textLink[data-icon-prev] a {
  padding-left: 30px;
}
.textLink[data-icon-prev] a:after {
  position: absolute;
  left: 0;
  top: calc(50% - 19px);
}
.textLink[data-icon-next] a {
  padding-right: 30px;
}
.textLink[data-icon-next] a:after {
  position: absolute;
  right: 0;
  top: calc(50% - 19px);
}
@media only screen and (max-width: 900px) {
  .textLink[data-icon-next] a:after {
    top: calc(50% - 17px);
  }
}
.textLink[data-align=center] {
  display: flex;
  justify-content: center;
}

.trigger {
  cursor: pointer;
  position: relative;
  width: 100px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  position: fixed;
  background-color: #E2528E;
  -webkit-transition: 1s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: 1s cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: 1s cubic-bezier(0.77, 0, 0.175, 1);
  transition: 1s cubic-bezier(0.77, 0, 0.175, 1);
}
@media only screen and (max-width: 900px) {
  .trigger {
    width: 80px;
    height: 58px;
  }
}
@media only screen and (min-width: 901px) {
  .trigger:hover .trigger_inner span {
    transition-delay: 0 !important;
  }
}
.trigger_inner {
  position: relative;
  width: 50px;
  height: 19px;
  display: block;
  transition: all 0.4s;
  box-sizing: border-box;
}
@media only screen and (max-width: 900px) {
  .trigger_inner {
    width: 40px;
    height: 15px;
  }
}
.trigger_inner span {
  display: inline-block;
  transition: all 0.4s;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #fff;
}
.trigger_inner span:nth-child(1) {
  top: 0;
  left: 0;
  -webkit-animation: menu-bar01 0.5s forwards;
  animation: menu-bar01 0.5s forwards;
}
.trigger_inner span:nth-child(2), .trigger_inner span:nth-child(3) {
  height: 1px;
}
.trigger_inner span:nth-child(2) {
  top: 50%;
  opacity: 1;
}
.trigger_inner span:nth-child(3) {
  top: 100%;
  -webkit-animation: menu-bar02 0.5s forwards;
  animation: menu-bar02 0.5s forwards;
}
.show-menu .trigger_inner span:nth-child(1) {
  -webkit-animation: active-menu-bar01 0.5s forwards;
  animation: active-menu-bar01 0.5s forwards;
  top: -14px;
}
.show-menu .trigger_inner span:nth-child(2) {
  opacity: 0;
}
.show-menu .trigger_inner span:nth-child(3) {
  -webkit-animation: active-menu-bar03 0.5s forwards;
  animation: active-menu-bar03 0.5s forwards;
  top: 28px;
}
@keyframes menu-bar01 {
  0% {
    transform: translateY(21px) rotate(45deg);
  }
  50% {
    transform: translateY(15px) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}
@keyframes menu-bar02 {
  0% {
    transform: translateY(-21px) rotate(-45deg);
  }
  50% {
    transform: translateY(-15px) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}
@keyframes active-menu-bar01 {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(21px) rotate(0);
  }
  100% {
    transform: translateY(21px) rotate(45deg);
  }
}
@keyframes active-menu-bar03 {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(-21px) rotate(0);
  }
  100% {
    transform: translateY(-21px) rotate(-45deg);
  }
}

.homeGallery {
  margin-bottom: 210px;
}
@media only screen and (max-width: 900px) {
  .homeGallery {
    margin-bottom: 130px;
  }
}
.homeGallery_headline {
  position: absolute;
  width: 80px;
}
@media only screen and (min-width: 1600px) {
  .homeGallery_headline {
    width: 95px;
  }
}
@media only screen and (max-width: 900px) {
  .homeGallery_headline {
    width: 41px;
  }
}
.homeGallery_headline[data-position=top] {
  top: -30px;
  left: 80px;
}
@media only screen and (max-width: 900px) {
  .homeGallery_headline[data-position=top] {
    left: 10px;
  }
}
.homeGallery_headline[data-position=bottom] {
  right: 80px;
  bottom: -10px;
  transform: rotate(180deg);
}
@media only screen and (max-width: 900px) {
  .homeGallery_headline[data-position=bottom] {
    right: -10px;
    bottom: 80px;
  }
}
.homeGallery_wrap {
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.homeGallery_column {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (min-width: 901px) {
  .homeGallery_column {
    width: 50%;
    float: left;
  }
}
@media only screen and (min-width: 901px) {
  .homeGallery_column:nth-child(2) {
    margin-top: 120px;
  }
}
.homeGallery_item[data-size=large] {
  width: 100%;
}
.homeGallery_item[data-size=small] {
  width: 50%;
}
.homeGallery_item a {
  display: block;
}
.homeGallery_more {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}
@media only screen and (max-width: 900px) {
  .homeGallery_more {
    margin-top: 70px;
  }
}

.homeHero {
  position: relative;
}
@media only screen and (min-width: 901px) {
  .homeHero {
    height: 100vh;
  }
}
.homeHero_logo {
  position: absolute;
  left: 40px;
  top: 20px;
  z-index: 1;
}
@media only screen and (max-width: 900px) {
  .homeHero_logo {
    width: 70px;
    left: 20px;
    top: 12px;
  }
}
@media only screen and (min-width: 901px) {
  .homeHero_container {
    height: 100%;
  }
}
.homeHero_slider {
  height: 100%;
}
.homeHero_item {
  position: relative;
}
@media only screen and (min-width: 901px) {
  .homeHero_item {
    display: flex !important;
    height: 100%;
  }
}
.homeHero_image {
  position: relative;
}
@media only screen and (min-width: 901px) {
  .homeHero_image {
    width: 65.625%;
    height: 100%;
  }
}
@media only screen and (max-width: 900px) {
  .homeHero_image {
    height: 100vw;
  }
}
.homeHero_image > div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media only screen and (min-width: 901px) {
  .homeHero_content {
    width: 34.375%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/** slick
/**************************************/
.homeHero .slick-list {
  height: 100%;
}
.homeHero .slick-track {
  height: 100%;
}
.homeHero .slick-slide > div {
  height: 100%;
}

/** homeHeroCard
/**************************************/
.homeHeroCard {
  position: relative;
}
@media only screen and (min-width: 901px) {
  .homeHeroCard {
    width: 240px;
    margin-top: 40px;
  }
}
@media only screen and (max-width: 900px) {
  .homeHeroCard {
    display: flex;
    justify-content: center;
    width: 76.8%;
    margin: 50px auto 0;
  }
}
.homeHeroCard[data-status=new]:before {
  width: 100px;
  height: 75px;
  background-color: #fff;
  border: 1px solid #484848;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  content: "NEW";
  position: absolute;
  right: -90px;
  top: -85px;
  z-index: 1;
  font-size: 2rem;
  font-family: "Work Sans", sans-serif;
  letter-spacing: 0.075em;
}
@media only screen and (max-width: 900px) {
  .homeHeroCard[data-status=new]:before {
    width: 50px;
    height: 38px;
    font-size: 1rem;
    right: -20px;
    top: -40px;
  }
}
.homeHeroCard_image {
  text-align: center;
}
@media only screen and (max-width: 900px) {
  .homeHeroCard_image {
    height: 105px;
  }
}
.homeHeroCard_image img {
  display: inline-block;
}
@media only screen and (max-width: 900px) {
  .homeHeroCard_image img {
    width: auto;
    height: 100%;
    max-width: none;
  }
}
@media only screen and (min-width: 901px) {
  .homeHeroCard_content {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 900px) {
  .homeHeroCard_content {
    padding-left: 5%;
  }
}
.homeHeroCard_headline {
  text-align: center;
  font-size: 2.4rem;
  line-height: 1.5833333333;
  font-family: "Work Sans", sans-serif;
  letter-spacing: 0.075em;
}
@media only screen and (max-width: 900px) {
  .homeHeroCard_headline {
    font-size: 1.8rem;
  }
}
.homeHeroCard .textLink {
  margin-top: 40px;
}
@media only screen and (max-width: 900px) {
  .homeHeroCard .textLink {
    margin-top: 30px;
  }
}

/** homeHeroGauge
/**************************************/
@media only screen and (min-width: 901px) {
  .homeHeroGauge {
    position: absolute;
    right: 60px;
    bottom: 60px;
  }
}
@media only screen and (max-width: 900px) {
  .homeHeroGauge {
    margin-top: 50px;
  }
}
.homeHeroGauge_list {
  display: flex;
}
@media only screen and (min-width: 901px) {
  .homeHeroGauge_list {
    justify-content: flex-end;
  }
}
@media only screen and (max-width: 900px) {
  .homeHeroGauge_list {
    justify-content: center;
  }
}
.homeHeroGauge_list > div {
  background-color: #e5e5e5;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  margin-right: 20px;
  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.homeHeroGauge_list > div:last-child {
  margin-right: 0;
}
.homeHeroGauge_list > div > i {
  background-color: #E2528E;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  display: block;
}
.homeHeroGauge_list > div.is-active {
  pointer-events: none;
  width: 46px;
}
.homeImage {
  height: 800px;
  margin-top: 310px;
  position: relative;
}
@media only screen and (max-width: 900px) {
  .homeImage {
    height: 100vw;
    margin-top: 140px;
  }
}
.homeImage_media {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.snake {
  position: absolute;
  top: -100px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  z-index: 5;
  min-width: 1000px;
  pointer-events: none;
  width: 100vw;
}
.snake svg g path {
  fill: none;
  stroke: transparent;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}
.snake p {
  color: #fff;
  position: absolute;
}

.snake {
  min-width: 1000px;
  pointer-events: none;
  width: 100vw;
  position: relative;
  z-index: 1;
}
.snake g {
  fill: none;
  stroke: transparent;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}
.snake_text {
  color: #fff;
  position: absolute;
}
.snake_text .char,
.snake_text .whitespace {
  color: #E2528E;
  display: inline-block;
  font-size: 5rem;
  position: absolute;
  width: 0.7em;
  font-family: "Work Sans", sans-serif;
  letter-spacing: 0.075em;
}
@media only screen and (max-width: 900px) {
  .snake_text .char,
.snake_text .whitespace {
    font-size: 3rem;
  }
}
.snake_text .char[data-char="/"] {
  color: transparent;
}
.snake_text .whitespace {
  width: 0.4em;
}

.homeMovie {
  position: relative;
  padding-bottom: 180px;
  margin-top: 80px;
}
@media only screen and (max-width: 900px) {
  .homeMovie {
    padding-bottom: 90px;
    margin-top: 60px;
  }
}
.homeMovie:before {
  content: "";
  display: block;
  width: 100%;
  height: calc(100% - 140px);
  position: absolute;
  z-index: 0;
  left: 0;
  bottom: 0;
  background-color: #fff;
}
@media only screen and (max-width: 900px) {
  .homeMovie:before {
    height: calc(100% - 80px);
  }
}
.homeMovie_list {
  position: relative;
  z-index: 1;
}

.homeMovieCard {
  position: relative;
  margin-top: 120px;
}
@media only screen and (max-width: 900px) {
  .homeMovieCard {
    margin-top: 60px;
  }
}
.homeMovieCard:first-child {
  margin-top: 0;
}
.homeMovieCard:nth-child(even) a {
  flex-direction: row-reverse;
}
@media only screen and (min-width: 1600px) {
  .homeMovieCard:nth-child(even) .homeMovieCard_image {
    transform: translateX(15%);
  }
}
.homeMovieCard:nth-child(even) .homeMovieCard_content {
  left: 0;
}
.homeMovieCard:nth-child(even) .homeMovieCard_content:before {
  left: 0;
}
@media only screen and (max-width: 900px) {
  .homeMovieCard:nth-child(even) .homeMovieCard_content:before {
    left: 30px;
  }
}
@media only screen and (min-width: 1600px) {
  .homeMovieCard:nth-child(odd) .homeMovieCard_image {
    transform: translateX(-15%);
  }
}
.homeMovieCard:nth-child(odd) .homeMovieCard_content {
  right: 0;
}
@media only screen and (min-width: 901px) {
  .homeMovieCard:nth-child(odd) .homeMovieCard_content {
    text-align: right;
  }
}
.homeMovieCard:nth-child(odd) .homeMovieCard_content:before {
  right: 0;
}
@media only screen and (max-width: 900px) {
  .homeMovieCard:nth-child(odd) .homeMovieCard_content:before {
    right: auto;
    left: 30px;
  }
}
@media only screen and (min-width: 901px) {
  .homeMovieCard a {
    display: flex;
  }
}
.homeMovieCard_image {
  overflow: hidden;
  border-radius: 20px;
  position: relative;
}
.homeMovieCard_image:before {
  content: "\ea06";
}
@media only screen and (min-width: 901px) {
  .homeMovieCard_image {
    width: 61.0805860806%;
  }
}
.homeMovieCard_image:before {
  font-size: 22px;
  width: 77px;
  height: 77px;
  border-radius: 50%;
  border: 2px solid #fff;
  position: absolute;
  z-index: 2;
  color: #484848;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
@media only screen and (min-width: 1600px) {
  .homeMovieCard_image:before {
    font-size: 29px;
    width: 100px;
    height: 100px;
  }
}
@media only screen and (max-width: 900px) {
  .homeMovieCard_image:before {
    width: 50px;
    height: 50px;
    font-size: 15px;
  }
}
.homeMovieCard_image:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.1);
}
@media only screen and (min-width: 1600px) {
  .homeMovieCard_image {
    width: 67.1875%;
  }
}
.homeMovieCard_content {
  width: 100%;
  padding-bottom: 25px;
}
@media only screen and (min-width: 901px) {
  .homeMovieCard_content {
    position: absolute;
    bottom: 120px;
    bottom: 90px;
  }
}
@media only screen and (min-width: 1600px) {
  .homeMovieCard_content {
    bottom: 120px;
    padding-bottom: 35px;
  }
}
@media only screen and (max-width: 900px) {
  .homeMovieCard_content {
    padding-left: 60px;
    padding-bottom: 0;
  }
}
.homeMovieCard_content:before {
  content: "";
  background-color: #484848;
  display: block;
  position: absolute;
  width: 47.4358974359%;
  height: 1px;
  bottom: 0;
  z-index: 1;
}
@media only screen and (min-width: 1600px) {
  .homeMovieCard_content:before {
    width: 52.1875%;
  }
}
@media only screen and (max-width: 900px) {
  .homeMovieCard_content:before {
    width: 1px;
    height: 110px;
  }
}
@media only screen and (max-width: 900px) {
  .homeMovieCard_title {
    margin-top: 30px;
  }
}
.homeMovieCard_title span {
  display: block;
}
.homeMovieCard_title span.is-ja {
  font-size: 1.2rem;
}
@media only screen and (min-width: 1600px) {
  .homeMovieCard_title span.is-ja {
    font-size: 1.6rem;
  }
}
.homeMovieCard_title span.is-en {
  margin-top: 20px;
  font-size: 3.4rem;
  font-family: "Work Sans", sans-serif;
  letter-spacing: 0.075em;
}
@media only screen and (min-width: 1600px) {
  .homeMovieCard_title span.is-en {
    margin-top: 23px;
    font-size: 4.4rem;
  }
}
@media only screen and (max-width: 900px) {
  .homeMovieCard_title span.is-en {
    font-size: 2.4rem;
    margin-top: 15px;
  }
}

/** fancybox
/**************************************/
.fancybox-inner {
  position: relative;
  width: 100% !important;
  height: auto !important;
  padding-top: 56.25%;
}
.fancybox-inner iframe {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
@media only screen and (max-width: 900px) {
  .fancybox-inner iframe {
    margin: 0 !important;
  }
}