.banner {
	margin-top: 180px;
	@include wrap;

	@include onlyMobile() {
	  padding: 0;
		margin-top: 130px;
	}

	&_item {
		margin-top: 60px;

		@include onlyMobile() {
		  margin-top: 30px;
		}

		&:first-child {
			margin-top: 0;
		}

	  a {
			display: block;
			text-align: center;

			img {
				display: inline-block;
			}
		}
	}
}
