.itemCard {
  &:last-child {
    margin-right: 0;
  }

  a {
    display: block;

    &:hover {
      @include onlyDesktop() {
        .itemCard_image {
          > div {
            transform: scale(1.06);
          }

          &:before {
            height: 75%;
          }
        }

        .itemCard_imageSquare {
          > div {
            transform: scale(1.06);
          }

          &:before {
            height: 75%;
          }
        }
      }
    }
  }

  &_image {
    position: relative;

    /*&:before {
      content: '';
      width: 100%;
      height: 95%;
      display: block;
      position: absolute;
      left: 0;
      top: -40px;
      z-index: 0;
      @include transition(.6s $easeOutCirc);
    }*/

    > div {
      position: relative;
      z-index: 1;
      text-align: center;
      margin: 0 auto;
      text-align: center;
      height: 264px;
      @include transition(.9s $easeOutQuart);

      @include onlyMobile() {
        height: 200px;
      }

      img {
        width: auto;
        max-width: none;
        height: 100%;
        display: inline-block;
      }
    }
  }

  &_content {
    text-align: center;

    @include onlyDesktop() {
      margin-top: 55px;
    }

    @include onlyMobile() {
      margin-top: 30px;
    }
  }

  &_title {
    @include fontEn;

    @include onlyDesktop() {
      font-size: 2.0rem;
    }

    @include onlyMobile() {
      font-size: 1.6rem;
    }
  }

  &_model {
    @include fontEn;

    @include onlyDesktop() {
      font-size: 1.4rem;
      margin-top: 7px;
    }

    @include onlyMobile() {
      font-size: 1.2rem;
    }
  }

  &_price {
    @include fontEn;

    @include onlyDesktop() {
      font-size: 1.4rem;
    }

    @include onlyMobile() {
      font-size: 1.4rem;
    }

		span {
			font-size: 1.2rem;

			@include onlyMobile() {
			  font-size: 1.0rem;
			}
		}
  }
}



/** animation
/**************************************/
.itemCarousel {
  .itemCard {
    > a {
      // opacity: 0;
      @include transition(1.4s $easeOutQuart);
      // @include translate3d(0, 40px, 0);
    }
  }

  &.inView {
    .itemCard {
      $def: .2;

      @for $i from 0 through 30 {
        &:nth-child(#{$i + 1}) {
          a {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            transition-delay: #{$def * ($i + 2)}s;
          }
        }
      }
    }
  }
}
