// Color
$colorBlack: #484848;
$colorMain: #E2528E;

// Width
$widthMobile: 375;
$widthBreak: 900;
$widthBase: 1920;
$widthMain: 1280;

// CSS Transition Easing
// Default
$linear: cubic-bezier(.250, .250, .750, .750);
$ease: cubic-bezier(.250, .100, .250, 1.000);
$easeIn: cubic-bezier(.420, .000, 1.000, 1.000);
$easeOut: cubic-bezier(.000, .000, .580, 1.000);
$easeInOut: cubic-bezier(.420, .000, .580, 1.000);
// In
$easeInQuad: cubic-bezier(.550, .085, .680, .530);
$easeInCubic: cubic-bezier(.550, .055, .675, .190);
$easeInQuart: cubic-bezier(.895, .030, .685, .220);
$easeInQuint: cubic-bezier(.755, .050, .855, .060);
$easeInSine: cubic-bezier(.470, .000, .745, .715);
$easeInExpo: cubic-bezier(.950, .050, .795, .035);
$easeInCirc: cubic-bezier(.600, .040, .980, .335);
$easeInBack: cubic-bezier(.600, -.280, .735, .045);
// Out
$easeOutQuad: cubic-bezier(.250, .460, .450, .940);
$easeOutCubic: cubic-bezier(.215, .610, .355, 1.000);
$easeOutQuart: cubic-bezier(.165, .840, .440, 1.000);
$easeOutQuint: cubic-bezier(.230, 1.000, .320, 1.000);
$easeOutSine: cubic-bezier(.390, .575, .565, 1.000);
$easeOutExpo: cubic-bezier(.190, 1.000, .220, 1.000);
$easeOutCirc: cubic-bezier(.075, .820, .165, 1.000);
$easeOutBack: cubic-bezier(.175, .885, .320, 1.275);
// In Out
$easeInOutQuad: cubic-bezier(.455, .030, .515, .955);
$easeInOutCubic: cubic-bezier(.645, .045, .355, 1.000);
$easeInOutQuart: cubic-bezier(.770, .000, .175, 1.000);
$easeInOutQuint: cubic-bezier(.860, .000, .070, 1.000);
$easeInOutSine: cubic-bezier(.445, .050, .550, .950);
$easeInOutExpo: cubic-bezier(1.000, .000, .000, 1.000);
$easeInOutCirc: cubic-bezier(.785, .135, .150, .860);
$easeInOutBack: cubic-bezier(.680, -.550, .265, 1.550);
$daichiEase: cubic-bezier(.4, 0, .2, 1);
