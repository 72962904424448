.header {
	position: absolute;
	height: 80px;
	right: 0;
	top: 0;
	background-color: $colorMain;
	border-bottom-left-radius: 10px;

	@include onlyMobile() {
	  display: none;
	}

	&_wrap {
		height: 100%;
		display: flex;
		align-items: center;
		padding: 0 100px 0 40px;
	}

	&_menu {
		display: flex;

		a {
			display: block;
			margin-right: 30px;

			span {
				font-size: 1.5rem;
				color: #fff;
				@include fontEn;
			}
		}
	}
}



/** headerConfig
/**************************************/
.headerConfig {
	display: flex;
	align-items: center;
	margin-left: 10px;

	&_item {
		margin-right: 20px;

		a {
			color: #fff;
			display: block;
		}

	  &[data-config="mypage"] {
			a {
				@include iconfont('mypage');

				&:before {
				  font-size: 21px;
				}
			}
		}

	  &[data-config="favorite"] {
			a {
				@include iconfont('favorite');

				&:before {
				  font-size: 22px;
				}
			}
		}

	  &[data-config="cart"] {
			a {
				position: relative;
				@include iconfont('cart');

				&:before {
				  font-size: 25px;
				}

				i {
					width: 14px;
					height: 14px;
					background-color: #fff;
					border-radius: 50%;
					position: absolute;
					display: block;
					right: -5px;
					top: -5px;
					color: $colorMain;
					font-size: 10px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-style: normal;
				}
			}
		}
	}
}
