.seriesLists {
	@include onlyDesktop() {
		display: flex;
		flex-wrap: wrap;
	}

	@include onlyMobile() {
	  margin-top: 40px;
	}
}

.seriesList {
	position: relative;

	@include onlyDesktop() {
	  width: math.div(570, $widthMain) * 100%;

		&:nth-child(odd) {
			margin-right: math.div(140, $widthMain) * 100%;
			margin-top: 100px;
		}
	}

	@include onlyMobile() {
	  margin-top: 40px;
		padding-right: 20px;
	}

	a {
		display: block;
		border-radius: 20px;
		overflow: hidden;
	}

	&_label {
		position: absolute;
		right: -35px;
		top: 0;
		line-height: 1;
		z-index: 2;
		height: 100%;

		@include onlyMobile() {
		  right: 0;
		}

		span {
			position: absolute;
      top: 0;
      right: 0;
      display: block;
      line-height: 1;
      width: 100%;
			writing-mode: vertical-rl;
			@include fontEn;

      @include onlyDesktop() {
        font-size: 1.6rem;
      }
      @include onlyMobile() {
        font-size: 1.0rem;

        .ios & {
          right: 6px;
        }
      }
		}
	}

	&_image {
		padding-top: 75%;
		position: relative;

		@include onlyMobile() {
		  padding-top: 69.9%;
		}

	  > div {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: {
			  repeat: no-repeat;
			  position: center center;
			  size: cover;
			}
		}
	}

	&_content {
		background-color: #fff;
		display: flex;
		justify-content: center;
		padding: 40px 0;

		@include onlyMobile() {
			padding: 20px 0;
		}

		> div {
			display: flex;
			align-items: center;
		}
	}

	&_products {
		@include onlyDesktop() {
		  margin-right: 34px;
			height: 200px;
		}

		@include onlyMobile() {
			margin-right: 20px;
		  height: 80px;
		}

		img {
			width: auto;
			height: 100%;
			max-width: none;
		}
	}

	&_title {
	  span {
			display: block;

			&.is-en {
				font-size: 3.6rem;
				@include fontEn;

				@include onlyMobile() {
				  font-size: 1.8rem;
				}
			}

			&.is-ja {
				margin-top: 20px;
				font-size: 1.6rem;

				@include onlyMobile() {
					margin-top: 10px;
				  font-size: 1.0rem;
				}
			}
		}
	}
}
